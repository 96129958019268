import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const AgentEditFamilyDetails = ({
  registerData,
  handleRegisterData,
  stateAndDistricts,
  register,
}) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (stateAndDistricts.length > 0) {
      const districts = stateAndDistricts
        ? [...stateAndDistricts[0].districts]
        : [];
      setLocations([...districts]);
    }
  }, [stateAndDistricts]);

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Family Details</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family value
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="family_value"
              name="family_value"
              {...register("family_value")}
              className="SelectBoxs-searchpage-profileedit"
              style={{ width: 350 }}
              value={registerData?.family_value}
              onChange={handleRegisterData}
            >
              <option value=""> Select</option>
              <option value="Orthodox">Orthodox</option>
              <option value="Moderate">Moderate</option>
              <option value="Traditional">Traditional</option>
              <option value="Liberal">Liberal</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Father Name
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <input
              type="text"
              id="fathername"
              name="father_name"
              {...register("father_name")}
              className="SelectBoxs-searchpage-profileedit"
              placeholder="Enter Father name"
              value={registerData?.father_name}
              onChange={handleRegisterData}
            />
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Father's Occupation
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="fatheroccupation"
              name="father_occupation"
              {...register("father_occupation")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.father_occupation}
              onChange={handleRegisterData}
            >
              <option value="">Select</option>
              <option value="Goverment">Goverment</option>
              <option value="Business">Business</option>
              <option value="Defence">Defence</option>
              <option value="Self Employed">Self Employed</option>
              <option value="Private">Private</option>
              <option value="Not Working">Not Working</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            No. of Brothers
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="no_brothers"
              name="no_brothers"
              {...register("no_brothers")}
              className="SelectBoxs-searchpage-profileedit"
              style={{ width: 350 }}
              value={registerData?.no_brothers}
              onChange={handleRegisterData}
            >
              <option value="">Select</option>
              <option value="0">None</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family Type
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="family_type"
              name="family_type"
              {...register("family_type")}
              className="SelectBoxs-searchpage-profileedit"
              style={{ width: 350 }}
              value={registerData?.family_type}
              onChange={handleRegisterData}
            >
              <option value="">Select </option>
              <option value="Nuclear">Nuclear</option>
              <option value="Joint">Joint</option>
            </select>
          </div>
        </div>
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family Status
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="family_status"
              name="family_status"
              {...register("family_status")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.family_status}
              onChange={handleRegisterData}
            >
              <option value=""> Select</option>
              <option value="Rich Class">Rich Class</option>
              <option value="Upper Class">Upper Class</option>
              <option value="Middle Class">Middle Class</option>
              <option value="Lower Class">Lower Class</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Mother Name
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <input
              type="text"
              id="mothername"
              name="mother_name"
              {...register("mother_name")}
              className="SelectBoxs-searchpage-profileedit"
              placeholder="Enter Mother name"
              value={registerData?.mother_name}
              onChange={handleRegisterData}
            />
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Mother's Occupation
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="motheroccupation"
              name="mother_occupation"
              {...register("mother_occupation")}
              className="SelectBoxs-searchpage-profileedit"
              style={{ width: 350 }}
              value={registerData?.mother_occupation}
              onChange={handleRegisterData}
            >
              <option value=""> Select</option>
              <option value="Goverment">Goverment</option>
              <option value="Business">Business</option>
              <option value="Defence">Defence</option>
              <option value="Self Employed">Self Employed</option>
              <option value="Private">Private</option>
              <option value="House Wife">House Wife</option>
              <option value="Not Working">Not Working</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            No. of Sisters
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="no_sisters"
              name="no_sisters"
              {...register("no_sisters")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.no_sisters}
              onChange={handleRegisterData}
            >
              <option value="">Select</option>
              <option value="0">None</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family Location
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="cities"
              name="family_location"
              {...register("family_location")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.family_location} // Set value to basicDetails.fam_city_id
              onChange={handleRegisterData}
            >
              <option value="">Select</option>
              {locations.map((city) => (
                <option key={city.district} value={city.district}>
                  {city.district}
                </option>
              ))}
            </select>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default AgentEditFamilyDetails;
