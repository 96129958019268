import {
  and,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  or,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { firebaseApp } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";
import { green } from "@mui/material/colors";
import { Link, useParams } from "react-router-dom";

const Requests = ({ setChatData, from, userData }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const { profileId } = useParams();
  const userDetails = useSelector(getProfileDetails);
  const getUserDetails =
    from === "agent" ? { profile_id: profileId } : userDetails;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const unSub = onSnapshot(
      query(
        collection(firebaseDB, "lastMessages"),
        and(
          where("status", "==", "REQUESTED"),
          or(
            where("receiverId", "==", getUserDetails?.profile_id),
            where("senderId", "==", getUserDetails?.profile_id)
          )
        )
      ),
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => new Date(b.time) - new Date(a.time));
        setMessages(data);
      }
    );

    return () => {
      unSub();
    };
  }, []);

  const onReply = (user_id, user_name, user_image, type) => {
    setChatData({
      status: true,
      userId: user_id,
      name: user_name,
      image: user_image,
      from: type === "REQUESTS" ? "REQUESTS" : "",
    });
  };

  const handleIgnore = async (senderId, receiverId) => {
    const combinedId =
      senderId > receiverId
        ? `${receiverId}-${senderId}`
        : `${senderId}-${receiverId}`;

    const currentTimeInUTC = new Date().toUTCString();

    try {
      await updateDoc(doc(firebaseDB, "chats", combinedId), {
        messages: [],
      });

      await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
        message: "Your message request has been ignored",
        status: "REJECTED",
        isViewed: false,
        time: currentTimeInUTC,
      });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const deleteMessage = async (senderId, receiverId) => {
    const combinedId =
      senderId > receiverId
        ? `${receiverId}-${senderId}`
        : `${senderId}-${receiverId}`;

    try {
      await updateDoc(doc(firebaseDB, "chats", combinedId), {
        messages: [],
      });

      await deleteDoc(doc(firebaseDB, "chats", combinedId));
      await deleteDoc(doc(firebaseDB, "lastMessages", combinedId));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };
  return (
    <>
      {messages.length > 0 ? (
        <>
          {messages.map((msg, index) => (
            <MDBRow className="client-format" key={index}>
              <MDBCol size="6" sm="3" className="client-images">
                <Link
                  to={`${
                    from === "agent"
                      ? `/agent/all-profiles/${profileId}/${
                          profileId === msg?.senderId
                            ? msg?.receiverId
                            : msg?.senderId
                        }`
                      : `/profile-details/${
                          getUserDetails?.profile_id === msg?.senderId
                            ? msg?.receiverId
                            : msg?.receiverId
                        }`
                  }`}
                >
                  <img
                    src={
                      getUserDetails?.profile_id === msg?.senderId
                        ? msg?.receiverImage
                          ? msg?.receiverImage
                          : "/default-photo-url.png"
                        : msg?.senderImage
                        ? msg?.senderImage
                        : "/default-photo-url.png"
                    }
                    width={150}
                    alt={`msgimg${index}`}
                  />
                </Link>
              </MDBCol>
              <MDBCol size="6" sm="9" className="client-alldetails">
                <p>
                  {getUserDetails?.profile_id === msg?.senderId ? (
                    <>
                      You sent a message request to {""}
                      <span className="text-capitalize">
                        {msg?.receiverName} (
                        <Link
                          to={`${
                            from === "agent"
                              ? `/agent/all-profiles/${profileId}/${msg?.receiverId}`
                              : `/profile-details/${msg?.receiverId}`
                          }`}
                          style={{ color: "#303030 " }}
                        >
                          {msg?.receiverId}
                        </Link>
                        )
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      <div
                        className=""
                        style={{ color: "rgb(10, 185, 59)", fontWeight: "500" }}
                      >
                        <span className="text-capitalize">
                          {msg?.senderName} (
                          <Link
                            to={`${
                              from === "agent"
                                ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                                : `/profile-details/${msg?.senderId}`
                            }`}
                            style={{ color: "#303030 " }}
                          >
                            {msg?.senderId}
                          </Link>
                          )
                        </span>{" "}
                        sent you a message!
                      </div>
                    </>
                  )}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
                <hr />
                {getUserDetails?.profile_id === msg?.senderId ? (
                  <>
                    <p className="client-intraction">
                      Do you want to delete the request?{" "}
                      <button
                        className="client-button"
                        onClick={() =>
                          deleteMessage(msg?.senderId, msg?.receiverId)
                        }
                      >
                        Delete
                      </button>
                      <button
                        className="client-replay"
                        onClick={() =>
                          onReply(
                            msg?.receiverId,
                            msg?.receiverName,
                            msg?.receiverImage
                          )
                        }
                      >
                        Chat
                      </button>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="client-intraction">
                      Do you want to reply to the message?{" "}
                      <button
                        className="client-button"
                        onClick={() =>
                          handleIgnore(msg?.senderId, msg?.receiverId)
                        }
                      >
                        Ignore
                      </button>{" "}
                      <button
                        className="client-replay"
                        onClick={() =>
                          onReply(
                            msg?.senderId,
                            msg?.senderName,
                            msg?.senderImage,
                            "REQUESTS"
                          )
                        }
                      >
                        Reply
                      </button>
                    </p>
                  </>
                )}
              </MDBCol>
            </MDBRow>
          ))}
        </>
      ) : (
        <p className="nomessages">You don't have any new messages</p>
      )}
      {messages.map((msg, index) => (
        <div className="intrest_container mobile_intrest" key={index}>
          <div className="mobleft">
            <div className="left_img">
              <div className="img_new">
                <div className="message-mobileresponse">
                  <div style={{ position: "relative" }}>
                    <Link
                      to={`${
                        from === "agent"
                          ? `/agent/all-profiles/${profileId}/${
                              profileId === msg?.senderId
                                ? msg?.receiverId
                                : msg?.senderId
                            }`
                          : `/profile-details/${
                              getUserDetails?.profile_id === msg?.senderId
                                ? msg?.receiverId
                                : msg?.receiverId
                            }`
                      }`}
                    >
                      <img
                        src={msg?.senderImage || "default-photo-url.png"}
                        width={150}
                        alt={`msgimg${index}`}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="message-profiles">
              <div className="right_details" style={{ color: "#F39200" }}>
                <p>
                  {getUserDetails?.profile_id === msg?.senderId ? (
                    <>
                      You sent a message request to {""}
                      <span className="text-capitalize">
                        {msg?.receiverName} (
                        <Link
                          to={`${
                            from === "agent"
                              ? `/agent/all-profiles/${profileId}/${msg?.receiverId}`
                              : `/profile-details/${msg?.receiverId}`
                          }`}
                          style={{ color: "#303030 " }}
                        >
                          {msg?.receiverId}
                        </Link>
                        )
                      </span>
                    </>
                  ) : (
                    <>
                      <div
                        className=""
                        style={{ color: "rgb(10, 185, 59)", fontWeight: "500" }}
                      >
                        <span
                          className="text-capitalize"
                          style={{ color: "green" }}
                        >
                          {msg?.senderName} (
                          <Link
                            to={`${
                              from === "agent"
                                ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                                : `/profile-details/${msg?.senderId}`
                            }`}
                            style={{ color: "#303030 " }}
                          >
                            {msg?.senderId}
                          </Link>
                          )
                        </span>{" "}
                        sent you a message!
                      </div>
                    </>
                  )}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="" style={{ marginLeft: "2px" }}>
            {getUserDetails?.profile_id === msg?.senderId ? (
              <div>
                <div>
                  <p className="client-intraction">
                    Do you want to delete the request?{" "}
                  </p>
                </div>
                <div className="" style={{ marginLeft: "5rem" }}>
                  <button
                    className="client-button"
                    onClick={() =>
                      deleteMessage(msg?.senderId, msg?.receiverId)
                    }
                  >
                    Delete
                  </button>{" "}
                  <button
                    className="client-replay"
                    onClick={() =>
                      onReply(
                        msg?.receiverId,
                        msg?.receiverName,
                        msg?.receiverImage
                      )
                    }
                  >
                    Chat
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <p className="client-intraction">
                    Do you want to reply to the message?{" "}
                  </p>
                </div>
                <div className="" style={{ marginLeft: "5rem" }}>
                  <button
                    className="client-button"
                    onClick={() => handleIgnore(msg?.senderId, msg?.receiverId)}
                  >
                    Ignore
                  </button>{" "}
                  <button
                    className="client-replay"
                    onClick={() =>
                      onReply(
                        msg?.senderId,
                        msg?.senderName,
                        msg?.senderImage,
                        "REQUESTS"
                      )
                    }
                  >
                    Reply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default Requests;
