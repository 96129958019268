import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import AgentProfileApi from "../../../../../Api/Agent/AgentProfileApi";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const EditAgentPartnerPreference = ({
  partnerPreferenceData,
  setIsEditPartnerPreference,
  setSavedEditProfileDetails,
}) => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState();
  const [preferenceMatches, setPreferenceMatches] = useState({
    min_age: partnerPreferenceData?.partnerMinAge,
    max_age: partnerPreferenceData?.partnerMaxAge,
    min_height: partnerPreferenceData?.partnerMinHeightId,
    max_height: partnerPreferenceData?.partnerMaxHeightId,
    minIncome: partnerPreferenceData?.partnerMinIncomeId,
    maxIncome: partnerPreferenceData?.partnerMaxIncomeId,
    marital_status: partnerPreferenceData?.partnerMaritalStatus,
    drinking_habit: partnerPreferenceData?.partnerDrinkingHabit,
    eating_habit: partnerPreferenceData?.partnerEatingHabit,
    physical_status: partnerPreferenceData?.partner_physical_status,
    smoking_habit: partnerPreferenceData?.partnerSmokingHabit,
    professional_status: "",
    professional_status_id: "",
    selectedReligions: [...partnerPreferenceData?.partnerReligion],
    selectedCastes: [],
  });
  const [preferenceDetails, setPreferenceDetails] = useState({
    listHeights: [],
    listStars: [],
    religionCaste: [],
    occupationList: [],
    annualIncomeList: [],
    stateAndDistricts: [],
  });
  const [selectedReligions, setSelectedReligions] = useState([
    ...partnerPreferenceData?.partnerReligion.map(
      (religion) => religion.religion
    ),
  ]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [selectedCastes, setSelectedCastes] = useState([
    ...partnerPreferenceData?.partnerCaste.map((caste) => caste.id),
  ]);
  const [selectedReligionIds, setSelectedReligionIds] = useState([
    ...partnerPreferenceData?.partnerReligion.map((religion) => religion.id),
  ]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);

  useEffect(() => {
    fetchPreferenceListDetails();
  }, []);
  useEffect(() => {
    if (selectedReligionIds.includes("0")) {
      setCasteOptions([]);
      setSelectedCastes([0]);
      setSelectedReligionIds([0]);
    } else if (preferenceDetails?.religionCaste) {
      const newCasteOptions = preferenceDetails?.religionCaste
        .filter((rel) => selectedReligions.includes(rel.religion))
        .flatMap((rel) => rel.caste)
        .map((caste) => ({
          label: caste.caste,
          value: caste.id,
        }));
      setCasteOptions(newCasteOptions);
      const validCasteIds = newCasteOptions.map((caste) => caste.value);
      const validCastes = newCasteOptions.map((caste) => caste.label);
      setSelectedCastes(
        partnerPreferenceData?.partnerCaste
          .map((caste) => caste.id)
          .filter((caste) => validCasteIds.includes(caste))
      );
    }
  }, [selectedReligions, preferenceDetails?.religionCaste]);
  useEffect(() => {
    if (preferenceDetails?.stateAndDistricts) {
      const allLocations =
        preferenceDetails?.stateAndDistricts[0]?.districts || [];
      setLocations(allLocations);
    }
  }, [preferenceDetails?.stateAndDistricts]);
  useEffect(() => {
    if (
      partnerPreferenceData?.partnerLocation &&
      partnerPreferenceData?.partnerLocation.length > 0
    ) {
      const selectd = partnerPreferenceData?.partnerLocation.map(
        (item) => item.id
      );
      setSelectedLocations(selectd);
    }
    if (
      partnerPreferenceData?.partnerStar &&
      partnerPreferenceData?.partnerStar.length > 0
    ) {
      const selectd = partnerPreferenceData?.partnerStar.map((item) => item.id);
      setSelectedStars(selectd);
    }
    if (
      partnerPreferenceData?.partnerProfessionalStatus &&
      partnerPreferenceData?.partnerProfessionalStatus.length > 0
    ) {
      const selectd = partnerPreferenceData?.partnerProfessionalStatus.map(
        (item) => item.id
      );
      setSelectedProfessions(selectd);
    }
  }, [partnerPreferenceData]);
  const fetchPreferenceListDetails = async () => {
    await AgentProfileApi.getRegisterDetails().then((data) => {
      if (data.status) {
        let responseData = data?.data;
        setPreferenceDetails((prevValue) => ({
          ...prevValue,
          listHeights: responseData?.heights,
          listStars: responseData?.star,
          religionCaste: responseData?.religionCaste,
          occupationList: responseData?.occupation,
          annualIncomeList: responseData?.annual_income,
          stateAndDistricts: responseData?.stateDistrict,
        }));
      }
    });
  };
  const handleReligionChange = (values) => {
    if (values.includes("0")) {
      // Assuming "0" is the value for "Any"
      setSelectedReligions(["0"]); // Select "Any" and clear other selections
      setSelectedReligionIds([0]);
      setCasteOptions([]); // Clear caste options if "Any" is selected
      setSelectedCastes([0]);
    } else {
      setSelectedReligions(values);
      const religionIdMap = preferenceDetails?.religionCaste.reduce(
        (acc, rel) => {
          acc[rel.religion] = rel.id;
          return acc;
        },
        {}
      );
      setSelectedReligionIds(values.map((religion) => religionIdMap[religion]));
    }
  };
  const handleCasteChange = (values) => {
    setSelectedCastes(values);
  };

  const handleSelectLocation = (value) => {
    const findLocation = selectedLocations.find((item) => item === value);
    let newLocation = [];
    if (findLocation) {
      const filteredLocation = selectedLocations.filter(
        (location) => location !== value
      );
      newLocation = [...filteredLocation];
    } else {
      newLocation = [...selectedLocations, value];
    }
    setSelectedLocations([...newLocation]);
  };
  const handleSelectStar = (value) => {
    const findStar = selectedStars.find((item) => item === value);
    let newStar = [];
    if (findStar) {
      const filteredStar = selectedStars.filter((star) => star !== value);
      newStar = [...filteredStar];
    } else {
      newStar = [...selectedStars, value];
    }
    setSelectedStars([...newStar]);
  };
  const handleSelectProfession = (value) => {
    const findProfession = selectedProfessions.find((item) => item === value);
    let newProfessions = [];
    if (findProfession) {
      const filteredProfessions = selectedProfessions.filter(
        (profession) => profession !== value
      );
      newProfessions = [...filteredProfessions];
    } else {
      newProfessions = [...selectedProfessions, value];
    }
    setSelectedProfessions([...newProfessions]);
  };
  const handleSave = async (e) => {
    e.preventDefault();
    let payload = {
      profileId: profileId,
      age_from: preferenceMatches?.min_age,
      age_to: preferenceMatches?.max_age,
      height_from: preferenceMatches?.min_height,
      height_to: preferenceMatches?.max_height,
      matrial_status: preferenceMatches?.marital_status,
      eating_habit: preferenceMatches?.eating_habit,
      drinking_habit: preferenceMatches?.drinking_habit,
      smoking_habit: preferenceMatches?.smoking_habit,
      physical_status: preferenceMatches?.physical_status,
      income_from: preferenceMatches?.minIncome,
      income_to: preferenceMatches?.maxIncome,
      selectedStar: [...selectedStars],
      selectedLocation: [...selectedLocations],
      selectedProfession: [...selectedProfessions],
      selectedReligions:
        selectedReligionIds.length > 0 ? [...selectedReligionIds] : [],
      selectedCastes: selectedCastes.length > 0 ? [...selectedCastes] : [],
    };
    try {
      setSavedEditProfileDetails(false);
      const data = await AgentProfileApi.updatePartnerPreference(
        token,
        payload
      );
      if (data.status) {
        setShowSuccessModal(true);
        setSavedEditProfileDetails(true);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/agent/login");
      }
    } catch (error) {
      console.lnavigateog("Error updating preferences:", error);
    }
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal();
    setIsEditPartnerPreference(false);
  };
  return (
    <>
      <h4 style={{ textAlign: "center", margin: "20px 0px" }}>
        Edit Partner Preferences
      </h4>
      <div className="profile-editprofile">
        <form onSubmit={handleSave}>
          <div className="profile-editprofile-title ">
            <p className="profile-description-profiledetails">Basic Details</p>
            <button type="submit">Save</button>
          </div>
          <MDBRow>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>Age From</div>:
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="ageFrom"
                    name="ageFrom"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.min_age || ""}
                    onChange={(e) => {
                      const selectedAgeFrom =
                        e.target.value === "any"
                          ? "any"
                          : parseInt(e.target.value);
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        min_age: selectedAgeFrom,
                        max_age:
                          selectedAgeFrom === "any"
                            ? "any"
                            : selectedAgeFrom >= prev.max_age
                            ? selectedAgeFrom + 1
                            : prev.max_age,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option key="any" value="any">
                      Any
                    </option>
                    {Array.from({ length: 83 }, (_, index) => (
                      <option key={index} value={18 + index}>
                        {18 + index} years
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>Age To</div>:
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="ageTo"
                    name="ageTo"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.max_age || ""}
                    onChange={(e) => {
                      const selectedAgeTo =
                        e.target.value === "any"
                          ? "any"
                          : parseInt(e.target.value);
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        max_age: selectedAgeTo,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option key="any" value="any">
                      Any
                    </option>
                    {preferenceMatches?.min_age !== "any" &&
                      Array.from(
                        {
                          length:
                            60 - (parseInt(preferenceMatches?.min_age) || 18),
                        },
                        (_, index) => (
                          <option
                            key={index}
                            value={
                              (parseInt(preferenceMatches?.min_age) || 18) +
                              1 +
                              index
                            }
                          >
                            {(parseInt(preferenceMatches?.min_age) || 18) +
                              1 +
                              index}{" "}
                            years
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Marital Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="maritalStatus"
                    name="maritalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.marital_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        marital_status: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="Marital Status">
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never Married">Never Married</option>
                    <option value="widowed">widowed</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Seperated">Seperated</option>
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Eating Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="eating"
                    name="eating"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.eating_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        eating_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>

                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non vegetarian">Non vegetarian</option>
                    <option value="Eggetarian">Eggetarian</option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Smoking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="smoke"
                    name="smoke"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.smoking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        smoking_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never smokes">Never smokes</option>
                    <option value="Smoke occasionally">
                      Smoke occasionally
                    </option>
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Driking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="drink"
                    name="drink"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.drinking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        drinking_habit: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Drinks socially">Drinks socially</option>
                    <option value="Never drinks">Never drinks</option>
                  </select>
                </div>
              </div>
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Height From
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="height_from"
                    name="height_from"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.min_height || ""}
                    onChange={(e) => {
                      const selectedHeightFrom =
                        e.target.value === "any" ? "any" : e.target.value;
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        min_height: selectedHeightFrom,
                        max_height:
                          selectedHeightFrom === "any"
                            ? "any"
                            : prev.max_height !== "any" &&
                              selectedHeightFrom >= prev.max_height
                            ? ""
                            : prev.max_height,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option key="any" value="any">
                      Any
                    </option>
                    {preferenceDetails?.listHeights.map((height) => (
                      <option key={height.id} value={height.id}>
                        {`${height.height_cm} cm / ${height.height_feet}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Height To
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="height_to"
                    name="height_to"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.max_height || ""}
                    onChange={(e) => {
                      const selectedHeightTo =
                        e.target.value === "any" ? "any" : e.target.value;
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        max_height: selectedHeightTo,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {preferenceMatches?.min_height === "any" ? (
                      <option key="any" value="any">
                        Any
                      </option>
                    ) : (
                      <>
                        <option key="any" value="any">
                          Any
                        </option>
                        {preferenceDetails?.listHeights
                          .filter(
                            (height) =>
                              parseInt(height.height_cm) >
                              parseInt(preferenceMatches?.min_height)
                          )
                          .map((height) => (
                            <option key={height.id} value={height.id}>
                              {`${height.height_cm} cm / ${height.height_feet}`}
                            </option>
                          ))}
                      </>
                    )}
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Physical Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="physicalStatus"
                    name="physicalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.physical_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        physical_status: e.target.value,
                      });
                    }}
                  >
                    <option value="" disable>
                      Select
                    </option>

                    <option value="Normal">Normal</option>
                    <option value="Physically Challenged">
                      Physically Challenged
                    </option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Annual Income From
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="income_from"
                    name="income_from"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.minIncome || ""}
                    onChange={(e) => {
                      const selectedHeightFrom =
                        e.target.value === "any" ? "any" : e.target.value;
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        minIncome: selectedHeightFrom,
                        maxIncome:
                          selectedHeightFrom === "any"
                            ? "any"
                            : prev.max_height !== "any" &&
                              selectedHeightFrom >= prev.maxIncome
                            ? ""
                            : prev.maxIncome,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option key="any" value="any">
                      Any
                    </option>
                    {preferenceDetails?.annualIncomeList.map((height) => (
                      <option key={height.id} value={height.id}>
                        {`${height.annual_income} `}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Annual Income To
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="income_to"
                    name="income_to"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.maxIncome || ""}
                    onChange={(e) => {
                      const selectedHeightTo =
                        e.target.value === "any" ? "any" : e.target.value;
                      setPreferenceMatches((prev) => ({
                        ...prev,
                        maxIncome: selectedHeightTo,
                      }));
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {preferenceMatches?.minIncome === "any" ? (
                      <option key="any" value="any">
                        Any
                      </option>
                    ) : (
                      <>
                        <option key="any" value="any">
                          Any
                        </option>
                        {preferenceDetails?.annualIncomeList
                          .filter(
                            (height) =>
                              parseInt(height.annual_income) >
                              parseInt(preferenceMatches?.minIncome)
                          )
                          .map((height) => (
                            <option key={height.id} value={height.id}>
                              {`${height.annual_income}`}
                            </option>
                          ))}
                      </>
                    )}
                  </select>
                </div>
              </div>
            </MDBCol>
            {/* Religion */}
            <MDBRow>
              <MDBCol size="6" sm="6" className="partner-profileedit">
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Religion
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select religions"
                      value={selectedReligions}
                      onChange={handleReligionChange}
                      options={[
                        { label: "Any", value: "0" },
                        ...preferenceDetails?.religionCaste.map((rel) => ({
                          label: rel.religion,
                          value: rel.religion,
                          disabled: selectedReligions.includes("0"),
                        })),
                      ]}
                    />
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
            {/* Caste */}
            <MDBRow>
              <MDBCol size="8" sm="8" className="partner-profileedit">
                {selectedReligions.length > 0 &&
                  !selectedReligions.includes("0") && (
                    <div className="annual-income" style={{ display: "flex" }}>
                      <div style={{ flex: 0.3 }} className="">
                        Caste
                      </div>
                      :
                      <div className="basic-profiles" style={{ flex: 1 }}>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select castes"
                          value={selectedCastes}
                          onChange={handleCasteChange}
                          options={casteOptions}
                        />
                      </div>
                    </div>
                  )}
              </MDBCol>
            </MDBRow>
            <p className="profile-description">Locations</p>
            <ul className="ks-cboxtags">
              {locations.map((location, index) => {
                var isSelected = selectedLocations.includes(location.dist_id);
                return (
                  <li key={index}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {}}
                    />
                    <label
                      htmlFor="checkboxOne"
                      onClick={() => handleSelectLocation(location?.dist_id)}
                    >
                      {location?.district}
                    </label>
                  </li>
                );
              })}
            </ul>
            {/* STAR */}
            <p className="profile-description">Stars</p>
            <ul className="ks-cboxtags">
              {preferenceDetails?.listStars.map((star, index) => {
                const findStar = selectedStars.find((item) => item === star.id);
                let isSelected = false;
                if (findStar) {
                  isSelected = true;
                }
                return (
                  <li key={index}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {}}
                    />
                    <label
                      htmlFor="checkboxOne"
                      onClick={() => handleSelectStar(star?.id)}
                    >
                      {star?.star}
                    </label>
                  </li>
                );
              })}
            </ul>
            {/* PROFESSIONAL STATUS */}
            <p className="profile-description">Professional Status</p>
            <ul className="ks-cboxtags">
              {preferenceDetails?.occupationList.map((occupation, index) => {
                const findProfession = selectedProfessions.find(
                  (item) => item === occupation.id
                );
                let isSelected = false;
                if (findProfession) {
                  isSelected = true;
                }
                return (
                  <li key={index}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {}}
                    />
                    <label
                      htmlFor="checkboxOne"
                      onClick={() => handleSelectProfession(occupation?.id)}
                    >
                      {occupation.occupation}
                    </label>
                  </li>
                );
              })}
            </ul>
          </MDBRow>
        </form>
      </div>
      <MDBModal
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        centered
      >
        <MDBModalDialog>
          <MDBModalHeader
            className="submit-register text-center"
            style={{
              borderBottom: "0px",
              border: "10px",
              marginTop: "10rem",
            }}
          >
            <MDBModalTitle className="register-sucess-title mx-auto">
              <img
                src="/Images/reg-success.png"
                style={{
                  width: "25%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                }}
              ></img>
              <br></br>
              <p className="reg-success-message">
                Successfully updated partner preferences
              </p>
              <MDBBtn
                color="none"
                onClick={handleCloseSuccessModal}
                className="custom-close-buttons"
              >
                OK
              </MDBBtn>
            </MDBModalTitle>
          </MDBModalHeader>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default EditAgentPartnerPreference;
