import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const AgentEditReligiousDetails = ({ religionCaste, registerData, errors }) => {
  const [casteList, setCasteList] = useState([]);

  useEffect(() => {
    const selectedData = religionCaste.find(
      (caste) => caste.id === registerData?.religion
    );
    if (selectedData) {
      setCasteList([...selectedData?.caste]);
    }
  }, [registerData, religionCaste]);

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Religious Details</p>
      </div>

      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Religion
          </div>
          :
          <div
            className={`basic-profiles  ${errors.religion && "border-danger"}`}
            style={{ flex: 1 }}
          >
            <select
              id="religion"
              name="religion"
              className="SelectBoxs-searchpage-profileedit"
              style={{ width: 350 }}
              value={registerData?.religion}
              disabled
            >
              <option value="">Select </option>
              {religionCaste.map((religion) => (
                <option key={religion.id} value={religion.id}>
                  {religion.religion}
                </option>
              ))}
            </select>
            {errors.religion && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.religion.message}
              </span>
            )}
          </div>
        </div>
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Caste
          </div>
          :
          <div
            className={`basic-profiles  ${errors.caste && "border-danger"}`}
            style={{ flex: 1 }}
          >
            <select
              id="caste"
              name="caste"
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.caste}
              disabled
            >
              <option value="">Select</option>
              {casteList.map((caste) => (
                <option key={caste.id} value={caste.id}>
                  {caste.caste}
                </option>
              ))}
            </select>
            {errors.caste && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.caste.message}
              </span>
            )}
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default AgentEditReligiousDetails;
