import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const AgentEditLocationDetails = ({
  registerData,
  setRegisterData,
  stateAndDistricts,
  register,
}) => {
  const [districtsList, setDistrictsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [isEditingLocation, setIsEditingLocation] = useState(true);

    useEffect(() => {
      setStatesFunction();
    }, [registerData, stateAndDistricts]);

    const setStatesFunction = async () => {
      let findState = await stateAndDistricts.find(
        (state) => state.id == registerData?.state
      );
      let findDistrict = {};
      let findCity = {};
      // if (userData?.state === "Not Defined" && stateAndDistricts.length === 1) {
      // findState = stateAndDistricts[0];
      // setDistrictsList([...stateAndDistricts[0].districts]);
      // } else {
      if (findState) {
        setDistrictsList([...findState.districts]);
        findDistrict = await findState.districts.find(
          (district) => district.dist_id == registerData?.district
        );
        if (findDistrict) {
          setCitiesList([...findDistrict?.cities]);
          findCity = findDistrict.cities.find(
            (city) => city?.city_id == registerData?.city
          );
        }
      }
    };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    if (selectedState) {
      const findState = stateAndDistricts.find(
        (state) => state.id == selectedState
      );
      setRegisterData((prevValue) => ({
        ...prevValue,
        state: selectedState,
        district: "",
        city: "",
      }));
      if (findState) {
        setDistrictsList([...findState?.districts]);
      } else {
        setDistrictsList([]);
      }
    } else {
      setRegisterData((prevValue) => ({
        ...prevValue,
        state: "",
        district: "",
        city: "",
      }));
    }
    setCitiesList([]);
  };

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    if (selectedDistrict) {
      const findDistrict = districtsList.find(
        (district) => district.dist_id == selectedDistrict
      );
      setRegisterData((prevValue) => ({
        ...prevValue,
        district: selectedDistrict,
        city: "",
      }));

      if (findDistrict) {
        setCitiesList([...findDistrict?.cities]);
      } else {
        setCitiesList([]);
      }
    } else {
      setRegisterData((prevValue) => ({
        ...prevValue,
        district: "",
        city: "",
      }));
      setCitiesList([]);
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      const findCity = citiesList.find((city) => city.city_id == selectedCity);
      setRegisterData((prevValue) => ({
        ...prevValue,
        city: selectedCity,
      }));
    } else {
      setRegisterData((prevValue) => ({
        ...prevValue,
        city: "",
      }));
    }
  };

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Location Details</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Select state
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="state"
                name="state"
                {...register("state")}
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.state}
                onChange={handleStateChange}
              >
                <option value="">Select</option>
                {stateAndDistricts.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.states}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Select state
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.state}
            </div>
          </div>
        )}

        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              District
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="district"
                name="district"
                {...register("district")}
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={registerData?.district}
                onChange={handleDistrictChange}
              >
                <option value="">Select</option>
                {districtsList.map((district) => (
                  <option key={district.dist_id} value={district.dist_id}>
                    {district.district}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              District
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.district}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              City
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="city"
                name="city"
                {...register("city")}
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={registerData?.city}
                onChange={handleCityChange}
              >
                <option value="">Select</option>
                {citiesList.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              City
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.city}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default AgentEditLocationDetails;
