import React, { useState } from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import Footer from "../../Footer";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import AllProfiles from "../AllProfiles";
import AgentAddNewProfileMain from "../AddProfile/Main";
import Search_container from "../../SearchProfiles/Search_container";

const AgentSearch = () => {
  const [selectedTab, setSelectedTab] = useState("Home");

  return (
    <>
      <Helmet>
        <title>Agent-Search | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <section>
        <AgentNavbar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={() => {}}
        />
        <Container>
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={() => {}}
            />
            {selectedTab === "Home" && <Search_container />}
            {selectedTab === "AllProfiles" && <AllProfiles />}
            {selectedTab === "AddProfile" && <AgentAddNewProfileMain />}
          </MDBRow>
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default AgentSearch;
