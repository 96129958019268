import { MDBCol } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Messagemenu from "../../../Messagemenu";
import { useParams } from "react-router-dom";
import api from "../../../../Api/Agent/AgentProfileApi";
import Requests from "../../../Messages/Requests";
import History from "../../../Messages/History";
import Ignored from "../../../Messages/Ignored";

const AgentMessageInboxList = ({setChatData, userData, setUserData}) => {
  const { profileId } = useParams();
  const token = localStorage.getItem("authToken");
  const [tabActive, setTabActive] = useState("requests");
  const changeTabs = (value) => {
    if (tabActive === value) {
      return;
    }
    setTabActive(value);
  };
  useEffect(() => {
    getAgentProfiles();
  }, []);
  const getAgentProfiles = async () => {
    api.getAgentMyProfiles(token).then(async ({ data }) => {
      if (data.status) {
        let listProfiles = data?.data?.profiles;
        let user = await listProfiles.find(
          (list) => list.profileId === profileId
        );
        if (user) {
          setUserData({
            name: user?.name,
            profileId: profileId,
            image: user?.image,
          });
        }
      }
    });
  };
  return (
    <>
      <MDBCol size="6" sm="9" className="messages-mobile">
        <div className="">
          <h3 className="heading">Messages({userData?.name}-{userData?.profileId})</h3>
          <hr />
        </div>
        <Messagemenu tabActive={tabActive} changeTabs={changeTabs} />
        {tabActive === "requests" && (
          <Requests setChatData={setChatData} from="agent" />
        )}
        {tabActive === "history" && (
          <History setChatData={setChatData} from="agent" />
        )}
        {tabActive === "ignored" && <Ignored setChatData={setChatData} from="agent" />}
      </MDBCol>
    </>
  );
};

export default AgentMessageInboxList;
