import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import api from "../../../Api/Agent/AgentProfileApi";
import { MDBRow } from "mdb-react-ui-kit";
import AgentProfileLeftDetails from "./LeftDetails/LeftDetails";
import AgentProfileRightDetails from "./RightDetails";
import AgentFooter from "../AgentFooter";

const AgentProfileDetails = () => {
  const token = localStorage.getItem("authToken");
  const { profileId } = useParams();
  const [profileData, setProfileData] = useState({});
  const [profileImages, setProfileImages] = useState([]);
  const [moreProfiles, setMoreProfiles] = useState([]);
  const [savedEditProfileDetails, setSavedEditProfileDetails] = useState(false);
  useEffect(() => {
    fetchProfileDetails();
  }, [savedEditProfileDetails]);

  const fetchProfileDetails = () => {
    api.getAgentProfileById(profileId, token).then(({ data }) => {
      if (data?.status) {
        setProfileData(data?.data);
        setProfileImages(data?.data?.profileImage);
        setMoreProfiles(data?.data?.agentUsers);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Agent | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <AgentNavbar />
        <Container>
          <MDBRow className="profileedit-matches-all">
            <AgentProfileLeftDetails
              profileData={profileData}
              profileImages={profileImages}
              moreProfiles={moreProfiles}
            />
            <AgentProfileRightDetails
              profileData={profileData}
              profileId={profileId}
              setProfileImages={setProfileImages}
              profileImages={profileImages}
              setSavedEditProfileDetails={setSavedEditProfileDetails}
            />
          </MDBRow>
        </Container>
        <AgentFooter />
      </section>
    </>
  );
};

export default AgentProfileDetails;
