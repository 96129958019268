import React, { useEffect, useState } from "react";
import "../../../../../style/agentProfilePhoto.css";
import { PiPlusThin } from "react-icons/pi";
import CropPhoto from "../../../../Modals/CropPhoto";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { useParams } from "react-router-dom";
import CustomModal from "../../../../CustomModel";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";

const AgentProfilePhotos = ({ setProfileImages, profileImages }) => {
  const token = localStorage.getItem("authToken");
  const { profileId } = useParams();
  const [newImageData, setNewImageData] = useState({
    imageUrl: "",
    file: "",
  });
  const [cropedImage, setCropedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    await api.getAgentUserProfileImages(profileId, token).then(({ data }) => {
      console.log("odaa", data);
      if (data.status) {
        setImages([...data?.data?.imageArray]);
      }
    });
  };
  const isAllowedFileType = (file) => {
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes("." + fileExtension);
  };
  const MAX_FILE_SIZE = 15 * 1024 * 1024;
  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };
  const handleImageChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      if (!isAllowedFileType(file)) {
        alert("Only .jpeg, .jpg, and .png image files are allowed.");
        setNewImageData({
          imageUrl: "",
          file: "",
        });
        return;
      }
      if (!isFileSizeValid(file)) {
        alert("File size should be no larger than 15MB.");
        setNewImageData({
          imageUrl: "",
          file: "",
        });
        return;
      }
      reader.onload = function (event) {
        setNewImageData({
          imageUrl: reader.result,
          file: file,
        });
        // setImages([...images, event.target.result]);
        // console.log("image", event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmitCroppedImages = async (ImageFile) => {
    const base64Image = ImageFile;
    const formData = new FormData();
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object representing the image
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    formData.append("photo", blob, "image.jpg");
    try {
      api
        .uploadAgentUserProfilePhoto(profileId, formData, token)
        .then(({ data }) => {
          if (data.status) {
            setModalTitle("Success");
            const message = data.message;
            setModalMessage(message);
            setImages([
              ...images,
              {
                image: data?.data?.image,
                status: data?.data?.status,
                photoId: data?.data?.photoId,
              },
            ]);
          } else {
            setModalTitle("Error");
            const message = data.message;
            setModalMessage(message);
          }
          setNewImageData({
            imageUrl: "",
            file: "",
          });
        });
      setShowModal(true);
    } catch (error) {
      console.log("dd", error);
      setUploadError(error.response.data.error);
      console.error("Error uploading photos", error);
    }
  };

  const removeProfileImage = async (profileImageId, profileImgUrl) => {
    try {
      let payload = {
        photoId: profileImageId,
      };
      api
        .removeAgentUserProfileImage(profileId, payload, token)
        .then(({ data }) => {
          if (data.status) {
            setModalTitle("Success");
            const message = data.message;
            setModalMessage(message);
            setImages((prevValue) =>
              prevValue.filter((image) => image.photoId !== profileImageId)
            );
            setProfileImages((prevValue) =>
              prevValue.filter((image) => image.profileImage !== profileImgUrl)
            );
          } else {
            setModalTitle("Error");
            const message = data.message;
            setModalMessage(message);
          }
          setShowModal(true);
        });
    } catch (error) {
      console.error("Error removing photo:", error);
    }
  };
  const updateProfileImage = async (profileImageId, imageUrl) => {
    try {
      let payload = {
        photoId: profileImageId,
      };
      api
        .setAgentUserProfileImage(profileId, payload, token)
        .then(({ data }) => {
          if (data.status) {
            setModalTitle("Success");
            const message = data.message;
            setModalMessage(message);
          } else {
            setModalTitle("Error");
            const message = data.message;
            setModalMessage(message);
          }
          setShowModal(true);
        });
    } catch (error) {
      console.error("Error setting photo as profile image:", error);
    }
  };
  return (
    <>
      <h4
        id="agent-profile-match"
        style={{ textAlign: "center", margin: "20px 0px" }}
      >
        Photos
      </h4>
      <div className="profile-editprofile">
        <div className="row">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12 px-md-2 my-2 d-flex justify-content-center">
            <div
              className="custom-box"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <div className="custom-add-circle">
                <PiPlusThin className="icon" />
              </div>
              <p>Add More Photo</p>
              <input
                id="fileInput"
                type="file"
                accept="image/x-png,image/jpg,image/jpeg"
                style={{ display: "none" }} // Hide the input field
                onChange={(e) => handleImageChange(e)} // Handle file change
              />
            </div>
          </div>
          {images.map((image, index) => (
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12 px-md-2 my-2 add-photos-full d-flex justify-content-center"
              key={index}
            >
              <div className="custom-img-box">
                <div className="image-container">
                  <img
                    src={image?.image ? image?.image : "upload_image.svg"}
                    className={`upload upload-image ${
                      image?.status === "N" ? "image-blur" : ""
                    }`}
                    alt={`img-${index}`}
                  />
                  {image?.status !== "N" && (
                    <MDBDropdown className="agent-dropdown">
                      <MDBDropdownToggle style={{ paddingBottom: "2rem" }}>
                        ....
                      </MDBDropdownToggle>
                      <MDBDropdownMenu
                        style={{
                          transform: "inherit",
                          minWidth: "100%",
                          transform: "translate3d(-73px, 28px, 4px)",
                        }}
                      >
                        <MDBDropdownItem
                          link
                          onClick={() =>
                            updateProfileImage(image?.photoId, image?.image)
                          }
                        >
                          Set as Main
                        </MDBDropdownItem>

                        <MDBDropdownItem
                          link
                          onClick={() =>
                            removeProfileImage(image?.photoId, image?.image)
                          }
                        >
                          Delete
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  )}
                  {image?.status === "N" && (
                    <>
                      {" "}
                      <div className="agent-validation">
                        <p>Under validation</p>
                      </div>
                      <MDBDropdown className="agent-dropdown">
                        <MDBDropdownToggle style={{ paddingBottom: "2rem" }}>
                          ....
                        </MDBDropdownToggle>
                        <MDBDropdownMenu
                          style={{
                            transform: "inherit",
                            minWidth: "100%",
                            transform: "translate3d(-73px, 28px, 4px)",
                          }}
                        >
                          <MDBDropdownItem
                            link
                            onClick={() => removeProfileImage(image?.photoId)}
                          >
                            Delete
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {uploadError && <div className="error-message">{uploadError}</div>}
      </div>
      {newImageData.file && (
        <CropPhoto
          selectedImage={newImageData}
          onClose={() =>
            setNewImageData({
              imageUrl: "",
              file: "",
            })
          }
          from="list"
          setCropedImage={setCropedImage}
          handleSubmit={handleSubmitCroppedImages}
        />
      )}
      <CustomModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        title={modalTitle}
        message={modalMessage}
      />
    </>
  );
};

export default AgentProfilePhotos;
