import React, { useEffect, useState } from "react";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { useParams } from "react-router-dom";
import AgentMatchProfileCard from "../Matches/AgentMatchProfileCard";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

const InterestRecievedAgentProfileList = ({ activeTab }) => {
  const { profileId } = useParams();
  const token = localStorage.getItem("authToken");
  const [pagination, setPagination] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    const element = document.getElementById("agent-profile-match");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    fetchMatchProfiles();
  }, [pageDetails, activeTab]);
  const fetchMatchProfiles = () => {
    let payload = {
      page: pageDetails?.page,
    };
    api
      .getAgentLikeRecievedProfiles(profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setPagination(data?.data?.pagination);
          setProfiles(data?.data?.interest);
        }
      });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };
  const hideAgentProfile = async (id) => {
    let payload = {
      hideCode: id,
    };
    api
      .hideAgentProfile(profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setProfiles((prevValue) =>
            prevValue.filter((prv) => prv.profile_id !== id)
          );
          if (profiles.length === 1 && pageDetails?.page !== 1) {
            setPageDetails((prevValue) => ({
              ...prevValue,
              page: pageDetails?.page - 1,
            }));
          }
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const removeUnlikedProfile = (id) => {
    setProfiles((prevValue) =>
      prevValue.filter((prv) => prv.profile_id !== id)
    );
    if (profiles.length === 1 && pageDetails?.page !== 1) {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page - 1,
      }));
    }
  };
  const handleInterestResponse = async (userId, status) => {
    let payload = {
      otherProfileId: userId,
      status: status,
    };
    await api
      .acceptAgentProfileInterest(profileId, token, payload)
      .then(({ data }) => {
        if (data.status) {
          setProfiles((prevValue) =>
            prevValue.filter((prv) => prv.profile_id !== userId)
          );
          if (profiles.length === 1 && pageDetails?.page !== 1) {
            setPageDetails((prevValue) => ({
              ...prevValue,
              page: pageDetails?.page - 1,
            }));
          }
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.statusCode === 401) {
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <h4
        id="agent-profile-match"
        style={{ textAlign: "center", margin: "20px 0px" }}
      >
        Interest Received Profiles
      </h4>
      {profiles.length > 0 ? (
        profiles.map((profile, index) => (
          <AgentMatchProfileCard
            key={index}
            user={profile}
            hideAgentProfile={hideAgentProfile}
            from="likeRecieved"
            removeUnlikedProfile={removeUnlikedProfile}
            handleInterestResponse={handleInterestResponse}
          />
        ))
      ) : (
        <div className="profile-editprofile">
          <div className="d-flex justify-content-center fs-5 text-secondary align-items-center h-100">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              No profiles Liked
            </p>
          </div>
        </div>
      )}
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InterestRecievedAgentProfileList;
