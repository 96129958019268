import React, { useRef } from "react";
//import '../style/displaypage.css'
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { TbClipboardList } from "react-icons/tb";
import { PiUsersThreeThin } from "react-icons/pi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import {
  FaRegEyeSlash,
  FaRegThumbsUp,
  FaUsersViewfinder,
} from "react-icons/fa6";
import { TiTickOutline } from "react-icons/ti";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdBlock } from "react-icons/md";

function SliderTabs({ selectedTab, setSelectedTab }) {
  const menuRef = useRef(null);
  //background//
  const location = useLocation();

  const scrollRight = () => {
    //menuRef.current.scrollLeft += 300;
    menuRef.current.scrollBy(300, 0);
    console.log(menuRef.current);
  };
  const scrollLeft = () => {
    // menuRef.current.scrollLeft -= 300;
    menuRef.current.scrollBy(-300, 0);
  };

  return (
    <div className="menu-wrapper">
      <div className="menu-item" ref={menuRef}>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Profile Details" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Profile Details")}
        >
          <Button type="button" as={Link}>
            Profile Details
            <TbClipboardList className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Profile Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Profile Matches")}
        >
          <Button type="button" as={Link}>
            Profile Matches
            <PiUsersThreeThin className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Photos" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Photos")}
        >
          <Button type="button" as={Link}>
            Photos <HiOutlineSquares2X2 className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "visited" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("visited")}
        >
          <Button type="button" as={Link}>
            Who Visited
            <FaUsersViewfinder className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Liked" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Liked")}
        >
          <Button type="button" as={Link}>
            Interested
            <FaRegThumbsUp className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Received" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Received")}
        >
          <Button type="button" as={Link}>
            Received
            <FaRegThumbsUp className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Accepted" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Accepted")}
        >
          <Button type="button" as={Link}>
            Accepted
            <TiTickOutline className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "AcceptedByOther" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("AcceptedByOther")}
        >
          <Button type="button" as={Link}>
            Accepted By Other
            <TiTickOutline className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Rejected" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Rejected")}
        >
          <Button type="button" as={Link}>
            Rejected
            <IoCloseCircleOutline className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "RejectedByOther" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("RejectedByOther")}
        >
          <Button type="button" as={Link}>
            Rejected By Other
            <IoCloseCircleOutline className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "shortlist" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("shortlist")}
        >
          <Button type="button" as={Link}>
            My Shortlist
            <TbClipboardList className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "shortlistOther" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("shortlistOther")}
        >
          <Button type="button" as={Link}>
            Shortlisted By Other
            <TbClipboardList className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "blocked" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("blocked")}
        >
          <Button type="button" as={Link}>
            Blocked <MdBlock className="tab-icon" />
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Hide" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Hide")}
        >
          <Button type="button" as={Link}>
            Hide? <FaRegEyeSlash className="tab-icon" />
          </Button>
        </Link>
      </div>
      <span className="pointer left-pointer dis" onClick={scrollLeft}>
        <i className="fa fa-chevron-left"></i>
      </span>
      <span className="pointer right-pointer" onClick={scrollRight}>
        <i className="fa fa-chevron-right"></i>
      </span>
    </div>
  );
}

export default SliderTabs;
