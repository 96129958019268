import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/Agent/AgentProfileApi";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import stateApi from "../../../Api/AuthApi";
import CustomModal from "../../CustomModel";
import { toast } from "react-toastify";

const AgentProfileEditSection = () => {
  const token = localStorage.getItem("authToken");
  const [profile, setProfile] = useState({
    name: "",
    mobile: "",
    email: "",
    cityId: "",
    city: "",
    districtId: "",
    district: "",
    stateId: "",
    state: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [states, setStates] = useState([]);
  const [districtsList, setDistrictsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [showModalcustom, setShowModalcustom] = useState(false);
  const [modalMessagecustom, setModalMessagecustom] = useState("");
  useEffect(() => {
    fetchAgentDetails();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchStates();
    }
  }, [isEdit]);

  const fetchStates = async () => {
    await stateApi.getRegister().then(({ data }) => {
      if (data) {
        setStates(data?.stateDistrict);
        const values = data?.stateDistrict;
        const stateId = profile?.stateId;
        const districtId = profile?.districtId;

        if (stateId) {
          const findState = values?.find((state) => state.id == stateId);
          if (findState) {
            setDistrictsList(findState.districts || []);

            if (districtId) {
              const findDistrict = findState.districts?.find(
                (district) => district.dist_id == districtId
              );
              if (findDistrict) {
                setCitiesList(findDistrict.cities || []);
              }
            }
          }
        }
      }
    });
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    if (selectedState) {
      const findState = states.find((state) => state.id == selectedState);
      setProfile((prevValue) => ({
        ...prevValue,
        stateId: selectedState,
        state: findState.states,
        districtId: "",
        district: "",
        cityId: "",
        city: "",
      }));
      if (findState) {
        setDistrictsList([...findState?.districts]);
      } else {
        setDistrictsList([]);
      }
      clearErrors("state");
      setError("district", {
        type: "manual",
        message: "Please select district",
      });
      setError("city", {
        type: "manual",
        message: "Please select city",
      });
    } else {
      setProfile((prevValue) => ({
        ...prevValue,
        stateId: "",
        state: "",
        districtId: "",
        district: "",
        city: "",
        cityId: "",
      }));
      setError("state", {
        type: "manual",
        message: "Please select state",
      });
      setError("district", {
        type: "manual",
        message: "Please select district",
      });
      setError("city", {
        type: "manual",
        message: "Please select city",
      });
    }
    setCitiesList([]);
  };
  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    if (selectedDistrict) {
      const findDistrict = districtsList.find(
        (district) => district.dist_id == selectedDistrict
      );
      setProfile((prevValue) => ({
        ...prevValue,
        districtId: selectedDistrict,
        district: findDistrict?.district,
        cityId: "",
        city: "",
      }));

      if (findDistrict) {
        setCitiesList([...findDistrict?.cities]);
      } else {
        setCitiesList([]);
      }
      clearErrors("district");
      setError("city", {
        type: "manual",
        message: "Please select city",
      });
    } else {
      setProfile((prevValue) => ({
        ...prevValue,
        districtId: "",
        district: "",
        cityId: "",
        city: "",
      }));
      setCitiesList([]);
      setError("district", {
        type: "manual",
        message: "Please select district",
      });
      setError("city", {
        type: "manual",
        message: "Please select city",
      });
    }
  };
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      const findCity = citiesList.find((city) => city.city_id == selectedCity);
      setProfile((prevValue) => ({
        ...prevValue,
        cityId: selectedCity,
        city: findCity?.city,
      }));
      clearErrors("city");
    } else {
      setProfile((prevValue) => ({
        ...prevValue,
        cityId: "",
        city: "",
      }));
      setError("city", {
        type: "manual",
        message: "Please select city",
      });
    }
  };
  const schema = yup.object().shape({
    name: yup.string().required("Please enter name"),
    mobile: yup
      .string()
      .required("Please enter mobile")
      .matches(/^[0-9]{10}$/, "Please enter a valid mobile"),
    email: yup
      .string()
      .required("Please enter a valid email address")
      .email("Invalid email format")
      .matches(/\.com$|\.in$/, "Email must end with '.com' or '.in'"),
    state: yup.string().required("Please select state"),
    district: yup.string().required("Please select district"),
    city: yup.string().required("Please select city"),
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const handleProfile = (e) => {
    let { name, value } = e.target;
    setProfile((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    if (name === "name") {
      if (value) {
        clearErrors("name");
      } else {
        setError("name", {
          type: "manual",
          message: "Please enter name",
        });
      }
    }
    if (name === "mobile") {
      if (value) {
        clearErrors("mobile");
      } else {
        setError("mobile", {
          type: "manual",
          message: "Please enter mobile",
        });
      }
    }
    if (name === "email") {
      if (value) {
        clearErrors("email");
      } else {
        setError("email", {
          type: "manual",
          message: "Please enter email",
        });
      }
    }
  };
  const submitForm = async (formValue) => {
    api
      .updateAgentProfile(formValue, token)
      .then(({ data }) => {
        if (data.status) {
          setModalMessagecustom(data.message);
          setShowModalcustom(true);
          setIsEdit(false);
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const fetchAgentDetails = async () => {
    await api.getAgentProfileDetails(token).then(({ data }) => {
      if (data.status) {
        setProfile({
          name: data?.data?.name,
          mobile: data?.data?.phone,
          email: data?.data?.email,
          cityId: data?.data?.cityId,
          city: data?.data?.city,
          districtId: data?.data?.districtId,
          district: data?.data?.district,
          stateId: data?.data?.stateId,
          state: data?.data?.state,
        });
        if (data?.data?.stateId) {
          setValue("state", data?.data?.stateId);
        }
        if (data?.data?.districtId) {
          setValue("district", data?.data?.districtId);
        }
        if (data?.data?.cityId) {
          setValue("city", data?.data?.cityId);
        }
      }
    });
  };
  return (
    <MDBCol size="12" lg="9" className="full--matches">
      <div className="profile-editprofile">
        <p className="account-setting">Edit Profile</p>
        <hr></hr>
        <form onSubmit={handleSubmit(submitForm)}>
          <MDBRow>
            <div className="user-description mb-4">
              <div></div>
              <div className="editing-religious">
                {isEdit ? (
                  <button type="submit">Save</button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEdit(true);
                    }}
                  >
                    <MDBIcon far icon="edit" /> Edit
                  </button>
                )}
              </div>
            </div>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Name<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.name && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <input
                      type="text"
                      className="SelectBoxs-searchpage-profileedit"
                      name="name"
                      value={profile?.name}
                      {...register("name", { required: true })}
                      onChange={handleProfile}
                    />
                    {errors.name && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Name
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.name}
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Mobile<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.mobile && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <input
                      type="text"
                      className="SelectBoxs-searchpage-profileedit"
                      name="mobile"
                      value={profile?.mobile}
                      {...register("mobile", { required: true })}
                      onChange={handleProfile}
                    />
                    {errors.mobile && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.mobile.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Mobile
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.mobile}
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Email<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.email && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <input
                      type="text"
                      className="SelectBoxs-searchpage-profileedit"
                      name="email"
                      value={profile?.email}
                      {...register("email", { required: true })}
                      onChange={handleProfile}
                    />
                    {errors.email && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Email
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.email}
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    State<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.state && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <select
                      id="state"
                      name="state"
                      {...register("state", { required: true })}
                      className="SelectBoxs-searchpage-profileedit"
                      value={profile?.stateId}
                      onChange={handleStateChange}
                    >
                      <option value="">Select</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.states}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.state.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    State
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.state}
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    District<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.district && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <select
                      id="district"
                      name="district"
                      {...register("district", { required: true })}
                      className="SelectBoxs-searchpage-profileedit"
                      style={{ width: 350 }}
                      value={profile?.districtId}
                      onChange={handleDistrictChange}
                    >
                      <option value="">Select</option>
                      {districtsList.map((district) => (
                        <option key={district.dist_id} value={district.dist_id}>
                          {district.district}
                        </option>
                      ))}
                    </select>
                    {errors.district && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.district.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    District
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.district}
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEdit ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    City<span style={{ color: "#640348" }}>*</span>
                  </div>
                  :
                  <div
                    className={`basic-profiles  ${
                      errors.city && "border-danger"
                    }`}
                    style={{ flex: 1 }}
                  >
                    <select
                      id="city"
                      name="city"
                      {...register("city", { required: true })}
                      className="SelectBoxs-searchpage-profileedit"
                      style={{ width: 350 }}
                      value={profile?.cityId}
                      onChange={handleCityChange}
                    >
                      <option value="">Select</option>
                      {citiesList.map((city) => (
                        <option key={city.city_id} value={city.city_id}>
                          {city.city}
                        </option>
                      ))}
                    </select>
                    {errors.city && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.city.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    City
                  </div>
                  :
                  <div className="" style={{ flex: 1 }}>
                    {profile?.city}
                  </div>
                </div>
              )}
            </MDBCol>
          </MDBRow>
        </form>
      </div>
      <CustomModal
        show={showModalcustom}
        handleClose={() => setShowModalcustom(false)}
        title="Update Profile"
        handleCloseModal={() => setShowModalcustom(false)}
        message={modalMessagecustom}
        // message="Your password was changed successfully"
      ></CustomModal>
    </MDBCol>
  );
};

export default AgentProfileEditSection;
