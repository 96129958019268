import {
  and,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  or,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { firebaseApp } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";
import { Link, useParams } from "react-router-dom";

const Ignored = ({ setChatData, from }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const { profileId } = useParams();
  const userDetails = useSelector(getProfileDetails);
  const getUserDetails =
    from === "agent" ? { profile_id: profileId } : userDetails;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const unSub = onSnapshot(
      query(
        collection(firebaseDB, "lastMessages"),
        and(
          where("status", "==", "REJECTED"),
          or(
            where("receiverId", "==", getUserDetails?.profile_id),
            where("senderId", "==", getUserDetails?.profile_id)
          )
        )
      ),
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => new Date(b.time) - new Date(a.time));
        setMessages(data);
      }
    );

    return () => {
      unSub();
    };
  }, []);

  const deleteMessage = async (senderId, receiverId) => {
    const combinedId =
      senderId > receiverId
        ? `${receiverId}-${senderId}`
        : `${senderId}-${receiverId}`;

    try {
      await updateDoc(doc(firebaseDB, "chats", combinedId), {
        messages: [],
      });

      await deleteDoc(doc(firebaseDB, "chats", combinedId));
      await deleteDoc(doc(firebaseDB, "lastMessages", combinedId));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };
  return (
    <>
      {messages.length > 0 ? (
        <>
          {messages.map((msg, index) => (
            <MDBRow className="client-format" key={index}>
              <MDBCol size="6" sm="3" className="client-images">
                <Link
                  to={`${
                    from === "agent"
                      ? `/agent/all-profiles/${profileId}/${
                          profileId === msg?.senderId
                            ? msg?.receiverId
                            : msg?.senderId
                        }`
                      : `/profile-details/${
                          getUserDetails?.profile_id === msg?.senderId
                            ? msg?.receiverId
                            : msg?.receiverId
                        }`
                  }`}
                >
                  <img
                    src={
                      getUserDetails?.profile_id === msg?.senderId
                        ? msg?.receiverImage
                          ? msg?.receiverImage
                          : "/default-photo-url.png"
                        : msg?.senderImage
                        ? msg?.senderImage
                        : "/default-photo-url.png"
                    }
                    width={150}
                    alt={`msgimg${index}`}
                  />
                </Link>
              </MDBCol>
              <MDBCol size="6" sm="9" className="client-alldetails">
                <p className="text-capitalize">
                  {getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverName
                    : msg?.senderName}{" "}
                  <Link
                    style={{ color: "#F39200" }}
                    to={`${
                      from === "agent"
                        ? `/agent/all-profiles/${profileId}/${
                            profileId === msg?.senderId
                              ? msg?.receiverId
                              : msg?.senderId
                          }`
                        : `/profile-details/${
                            getUserDetails?.profile_id === msg?.senderId
                              ? msg?.receiverId
                              : msg?.senderId
                          }`
                    }`}
                  >
                    {getUserDetails?.profile_id === msg?.senderId
                      ? msg?.receiverId
                      : msg?.senderId}{" "}
                  </Link>
                </p>
                <p className="send-details"></p>
                <p className="client-message">
                  {getUserDetails?.profile_id === msg?.senderId
                    ? msg?.message
                    : "You ignored the message."}
                </p>
                <hr />
                <p className="client-intraction">
                  Do you want to delete to the message?{" "}
                  <button
                    className="client-replay"
                    onClick={() =>
                      deleteMessage(msg?.senderId, msg?.receiverId)
                    }
                  >
                    Delete
                  </button>
                </p>
              </MDBCol>
            </MDBRow>
          ))}
        </>
      ) : (
        <p className="nomessages">You don't have any new ignored messages.</p>
      )}
      {messages.map((msg, index) => (
        <div className="intrest_container mobile_intrest" key={index}>
          <div className="mobleft">
            <div className="left_img">
              <div className="img_new">
                <div className="message-mobileresponse">
                  <div style={{ position: "relative" }}>
                    <Link
                      to={`${
                        from === "agent"
                          ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                          : `/profile-details/${msg?.senderId}`
                      }`}
                    >
                      <img
                        src={msg?.senderImage || "/default-photo-url.png"}
                        width={150}
                        alt={`msgimg${index}`}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="message-profiles">
              <div className="right_details" style={{ color: "#F39200" }}>
                <p className="text-capitalize">
                  {msg?.senderName}{" "}
                  <Link
                    style={{ color: "#F39200" }}
                    to={`${
                      from === "agent"
                        ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                        : `/profile-details/${msg?.senderId}`
                    }`}
                   >
                    {msg?.senderId}
                  </Link>{" "}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="" style={{ marginLeft: "2px" }}>
            <div>
              <div>
                <p className="client-intraction">
                  Do you want to delete to the message?{" "}
                </p>
              </div>
              <div className="" style={{ marginLeft: "5rem" }}>
                <button
                  className="client-replay"
                  onClick={() => deleteMessage(msg?.senderId, msg?.receiverId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Ignored;
