import { MDBCol } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/Agent/AgentProfileApi";
import AgentProfileCard from "../AgentProfileCard";
import ReactPaginate from "react-paginate";
import CustomModal from "../../CustomModel";

const AllProfiles = ({ setSelectedTab }) => {
  const [pagination, setPagination] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [showModalcustom, setShowModalcustom] = useState(false);
  const [modalMessagecustom, setModalMessagecustom] = useState("");
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchAllProfiles();
  }, [pageDetails]);
  const fetchAllProfiles = async () => {
    setProfiles([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getAgentProfiles(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setProfiles(data?.data?.profiles);
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };
  const removeDeleteProfile = (id) => {
    setProfiles((prevValue) =>
      prevValue.filter((prev) => prev.profileId !== id)
    );
    if (profiles.length === 1 && pageDetails?.page !== 1) {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page - 1,
      }));
    }
  };
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="rgtr">
        <div className="position-relative">
          <img
            src="../Images/agent/banner-2.jpg"
            className="agent-banner position-relative"
            alt="..."
            style={{ width: "100%" }}
          />
          <img
            src="../Images/agent/register-logo.png"
            alt="register-logo"
            className="agent-register-logo position-absolute top-50 start-50 translate-middle w"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      </div>
      <div
        className={`pictures-client pt-4 ${profiles.length <= 0 && "h-50"}`}
        style={{ minHeight: "250px" }}
      >
        {profiles.length > 0 ? (
          profiles.map((profile, index) => (
            <AgentProfileCard
              key={index}
              user={profile}
              removeDeleteProfile={removeDeleteProfile}
              setModalMessagecustom={setModalMessagecustom}
              setShowModalcustom={setShowModalcustom}
            />
          ))
        ) : (
          <div className="d-flex flex-column justify-content-center fs-5 text-secondary align-items-center h-100">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              No profiles registered
            </p>
            <div className="text-center">
              <button
                className="btn mx-auto d-block agent-profile-submit-btn"
                onClick={() => {
                  setSelectedTab("AddProfile");
                  window.history.pushState(null, "", "/agent/add-profile");
                }}
              >
                Add Profile
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
      <CustomModal
        show={showModalcustom}
        handleClose={() => setShowModalcustom(false)}
        title="Delete Account"
        handleCloseModal={() => setShowModalcustom(false)}
        message={modalMessagecustom}
      />
    </MDBCol>
  );
};

export default AllProfiles;
