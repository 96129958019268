import React, { useEffect, useState } from "react";
import * as yup from "yup";
import AgentProfileApi from "../../../../../Api/Agent/AgentProfileApi";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AgentEditBasicDetails from "./AgentEditBasicDetails";
import AgentEditReligiousDetails from "./AgentEditReligiousDetails";
import AgentEditProfessionalDetails from "./AgentEditProfessionalDetails";
import AgentEditLocationDetails from "./AgentEditLocationDetails";
import AgentEditFamilyDetails from "./AgentEditFamilyDetails";
import AgentEditHabits from "./AgentEditHabits";
import AgentEditHobbies from "./AgentEditHobbies";
import { toast } from "react-toastify";
import {
  MDBBtn,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
const EditAgentUserProfile = ({
  profileData,
  setIsEditProfile,
  setSavedEditProfileDetails
}) => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorModal, setErrorModal] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState();
  const [registerDetails, setRegisterDetails] = useState({
    listHeights: [],
    listStars: [],
    religionCaste: [],
    educationList: [],
    employedInList: [],
    occupationList: [],
    annualIncomeList: [],
    stateAndDistricts: [],
    hobbies: [],
  });
  const [registerData, setRegisterData] = useState({
    profile_description: profileData?.profileDescription,
    name: profileData?.name,
    gender: profileData?.gender,
    date_birth: profileData?.dob,
    age: profileData?.age,
    height: profileData?.heightId,
    matrial_status: profileData?.maritalStatus,
    any_disability: profileData?.anyDisability,
    weight: profileData?.weight,
    body_type: profileData?.bodyType,
    complexion: profileData?.complexion,
    star: profileData?.starId,
    physical_status: profileData?.physicalStatus,
    education_main: profileData?.mainQualificationId,
    education_sub: profileData?.subQualificationId,
    employed_in: profileData?.employedInId,
    occupation: profileData?.occupationId,
    annual_income: profileData?.annualIncomeId,
    state: profileData?.stateId,
    district: profileData?.districtId,
    city: profileData?.cityId,
    religion: profileData?.religionId,
    caste: profileData?.casteId,
    family_value: profileData?.familyValue,
    family_status: profileData?.familyStatus,
    family_type: profileData?.familyType,
    father_name: profileData?.fatherName,
    father_occupation: profileData?.fatherOccupation,
    mother_name: profileData?.motherName,
    mother_occupation: profileData?.motherOccupation,
    no_brothers: profileData?.noOfBrothers,
    no_sisters: profileData?.noOfSisters,
    family_location: profileData?.familyLocation,
    eating_habit: profileData?.eatingHabit,
    smoking_habit: profileData?.smokingHabit,
    drinking_habit: profileData?.drinkingHabit,
    hobbies: [...profileData?.hobbies],
  });
  useEffect(() => {
    fetchRegisterListDetails();
  }, []);
  useEffect(() => {
    if (profileData) {
      const registerMapping = {
        profile_description: "profileDescription",
        name: "name",
        gender: "gender",
        date_birth: "dob",
        age: "age",
        height: "heightId",
        matrial_status: "maritalStatus",
        any_disability: "anyDisability",
        weight: "weight",
        body_type: "bodyType",
        complexion: "complexion",
        star: "starId",
        physical_status: "physicalStatus",
        education_main: "mainQualificationId",
        education_sub: "subQualificationId",
        employed_in: "employedInId",
        occupation: "occupationId",
        annual_income: "annualIncomeId",
        state: "stateId",
        district: "districtId",
        city: "cityId",
        religion: "religionId",
        caste: "casteId",
        family_value: "familyValue",
        family_status: "familyStatus",
        family_type: "familyType",
        father_name: "fatherName",
        father_occupation: "fatherOccupation",
        mother_name: "motherName",
        mother_occupation: "motherOccupation",
        no_brothers: "noOfBrothers",
        no_sisters: "noOfSisters",
        family_location: "familyLocation",
        eating_habit: "eatingHabit",
        smoking_habit: "smokingHabit",
        drinking_habit: "drinkingHabit",
        hobbies: "hobbies",
      };
      Object.keys(registerMapping).forEach((key) => {
        setValue(key, profileData[registerMapping[key]]);
      });
    }
  }, [profileData]);

  const fetchRegisterListDetails = async () => {
    await AgentProfileApi.getRegisterDetails().then((data) => {
      if (data.status) {
        let responseData = data?.data;
        setRegisterDetails((prevValue) => ({
          ...prevValue,
          listHeights: responseData?.heights,
          listStars: responseData?.star,
          religionCaste: responseData?.religionCaste,
          educationList: responseData?.education,
          employedInList: responseData?.employed_in,
          occupationList: responseData?.occupation,
          annualIncomeList: responseData?.annual_income,
          stateAndDistricts: responseData?.stateDistrict,
          hobbies: responseData?.hobbies,
        }));
      } else {
        if (data.statusCode === 401) {
          localStorage.clear();
          navigate("/agent/login");
        }
      }
    });
  };

  const schema = yup.object().shape({
    profile_description: yup.string().optional(),
    name: yup.string().required("Please enter name"),
    date_birth: yup.string().required("Please enter date of birth"),
    age: yup.string(),
    height: yup.string().optional(),
    matrial_status: yup.string().optional(),
    any_disability: yup.string().optional(),
    weight: yup.string().optional(),
    body_type: yup.string().optional(),
    complexion: yup.string().optional(),
    star: yup.string().optional(),
    physical_status: yup.string().optional(),
    education_main: yup.string().optional(),
    education_sub: yup.string().optional(),
    employed_in: yup.string().optional(),
    occupation: yup.string().optional(),
    annual_income: yup.string().optional(),
    state: yup.string().optional(),
    district: yup.string().optional(),
    city: yup.string().optional(),
    family_value: yup.string().optional(),
    family_status: yup.string().optional(),
    family_type: yup.string().optional(),
    father_name: yup.string().optional(),
    father_occupation: yup.string().optional(),
    mother_name: yup.string().optional(),
    mother_occupation: yup.string().optional(),
    no_brothers: yup.string().optional(),
    no_sisters: yup.string().optional(),
    family_location: yup.string().optional(),
    eating_habit: yup.string().optional(),
    smoking_habit: yup.string().optional(),
    drinking_habit: yup.string().optional(),
    hobbies: yup.array().optional(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleRegisterData = (e) => {
    let { name, value } = e.target;
    setRegisterData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (name === "name") {
      if (value) {
        clearErrors("name");
      } else {
        setError("name", {
          type: "manual",
          message: "Please enter name",
        });
      }
    }
  };
  const formatDate = (dateStr) => {
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

    if (datePattern.test(dateStr)) {
      const [day, month, year] = dateStr.split("/");
      return `${year}-${month}-${day}`;
    }

    return dateStr;
  };
  const submitForm = async (formValue) => {
    console.log(formValue);
    formValue.date_birth = await formatDate(formValue.date_birth);
    formValue.hobbies = await registerData.hobbies.map((hobby) => hobby.id);
    let token = localStorage.getItem("authToken");
    let payload = formValue;
    delete payload.gender;
    delete payload.religion;
    delete payload.caste;
    setSavedEditProfileDetails(false)
    await AgentProfileApi.editAgentCreatedProfile(profileId, token, payload)
      .then(({ data }) => {
        if (data.status) {
          setFormSubmitted(true);
          setShowSuccessModal(true);
          setSavedEditProfileDetails(true)
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error("Error while submitting forms:", error);
      });
  };
  const handleerrorCloseModal = () => {
    setErrorModal(false);
  };
  return (
    <>
      <h4 style={{ textAlign: "center", margin: "20px 0px" }}>
        Edit Profile Details
      </h4>
      <div className="profile-editprofile">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="profile-editprofile-title ">
            <p className="profile-description-profiledetails">
              Profile Description
            </p>
            <button type="submit">Save</button>
          </div>
          <textarea
            className="profile-description-textarea"
            rows={4}
            name="profile_description"
            value={registerData?.profile_description}
            {...register("profile_description")}
            onChange={handleRegisterData}
            style={{
              maxWidth: "100%",
              overflow: "auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          />
          <hr />
          <AgentEditBasicDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            listHeights={registerDetails?.listHeights}
            listStars={registerDetails?.listStars}
            register={register}
            setValue={setValue}
            errors={errors}
          />
          <hr />
          <AgentEditReligiousDetails
            religionCaste={registerDetails?.religionCaste}
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            register={register}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
          />
          <hr />
          <AgentEditProfessionalDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            educationList={registerDetails?.educationList}
            employedInList={registerDetails?.employedInList}
            occupationList={registerDetails?.occupationList}
            annualIncomeList={registerDetails?.annualIncomeList}
            register={register}
          />
          <hr />
          <AgentEditLocationDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            stateAndDistricts={registerDetails?.stateAndDistricts}
            register={register}
          />
          <hr />
          <AgentEditFamilyDetails
            registerData={registerData}
            handleRegisterData={handleRegisterData}
            stateAndDistricts={registerDetails?.stateAndDistricts}
            register={register}
          />
          <hr />
          <AgentEditHabits
            registerData={registerData}
            handleRegisterData={handleRegisterData}
            register={register}
          />
          <hr />
          <AgentEditHobbies
            registerData={registerData}
            setRegisterData={setRegisterData}
            hobbies={registerDetails?.hobbies}
          />
        </form>
      </div>
      <MDBModal show={errorModal} onHide={() => setErrorModal(false)}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="submit-register text-center">
              <MDBModalTitle className="register-sucess-title mx-auto">
                Updation Unsuccessfully
              </MDBModalTitle>
              <MDBBtn
                color="#fffff"
                onClick={handleerrorCloseModal}
                className="custom-close-button"
              >
                &times;
              </MDBBtn>
            </MDBModalHeader>

            <MDBModalFooter>
              <p className="reg-footer-unsuccessfull">
                {" "}
                Sorry, something went wrong while create new profile
              </p>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setIsEditProfile(false);
        }}
        centered
      >
        <MDBModalDialog>
          <MDBModalHeader
            className="submit-register text-center"
            style={{
              borderBottom: "0px",
              border: "10px",
              marginTop: "10rem",
            }}
          >
            {" "}
            <MDBModalTitle className="register-sucess-title mx-auto">
              <img
                src="/Images/reg-success.png"
                style={{
                  width: "25%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                }}
              ></img>
              <br></br>
              <p className="reg-success-message">Updated Successfully</p>
              <MDBBtn
                color="none"
                onClick={() => {
                  setShowSuccessModal(false);
                  setIsEditProfile(false);
                }}
                className="custom-close-buttons"
              >
                OK
              </MDBBtn>
            </MDBModalTitle>
          </MDBModalHeader>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default EditAgentUserProfile;
