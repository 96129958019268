const { axios, constants } = require("../../Config");
const baseURL = `${constants.baseURL}agent`;

class AgentSettingsFetch {
  updatePassword(payload, token) {
    return axios
      .post(`${baseURL}/resetPassword`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  deleteAccount(payload, token) {
    return axios
      .post(`${baseURL}/delete-agent`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getNotificationCount(token) {
    return axios
      .get(`${baseURL}/notification-count`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}
export default new AgentSettingsFetch();
