import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import UserCardBottom from "./UserCardBottom";
import api from "../../Api/Agent/AgentProfileApi";
import { toast } from "react-toastify";
import DeleteForm from "./AllProfiles/DeleteForm";

const AgentProfileCard = ({
  user,
  chatData,
  setChatData,
  removeDeleteProfile,
  setModalMessagecustom,
  setShowModalcustom,
}) => {
  const token = localStorage.getItem("authToken");
  const [isHide, setIsHide] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setError("");
  }, [showModal]);
  useEffect(() => {
    setIsHide(user?.isHide);
  }, [user]);

  const hideProfile = () => {
    api
      .hideAgentCreatedProfile(user?.profileId, token)
      .then(({ data }) => {
        if (data.status) {
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsHide(!isHide);
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedReason === "" && otherReason === "") {
        setError("Please share your reason");
      } else {
        setError("");
        const payload = {
          deleteReason: selectedReason,
          otherReason: otherReason,
        };
        api
          .deleteAgentProfile(user?.profileId, payload, token)
          .then(({ data }) => {
            if (data.status) {
              setShowModal(false);
              setModalMessagecustom(data.message);
              setShowModalcustom(true);
              removeDeleteProfile(user?.profileId);
            } else {
              setError(data.message);
            }
          })
          .catch((error) => {
            setError("An error occurred while deleting the account.");
            console.error(error);
          });
      }
    } catch (error) {
      setError("An error occurred while deleting the account.");
      console.error(error);
    }
  };

  return (
    <>
      {/* showing in lg */}
      <MDBRow className="matches-container d-none d-lg-flex">
        <MDBCol size="3" className="position-relative">
          <div className={`${user?.profile_image && "matches-border-img"}`}>
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              {user?.image ? (
                <img
                  src={user?.image}
                  alt="Profile Image"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="../default-photo-url.png"
                  alt="Default Image"
                  className="matches-profileimage"
                />
              )}
            </Link>
          </div>
        </MDBCol>
        <MDBCol size="1"></MDBCol>
        <MDBCol size="8">
          <div className="danil-sebastine">
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              <p className="danil">{user?.name}</p>
              <p className="danil-details">{user?.profileId}</p>
              <p className="danil-details">
                {" "}
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              <p className="danil-details">
                {" "}
                {user?.religion}/{user?.caste}
              </p>
              <p className="danil-details">
                {user?.qualification || "education not defined"}
                {user?.occupation
                  ? `, ${user?.occupation}`
                  : ", occupation not defined"}
                {user?.district && `, ${user?.district}`}
              </p>
            </Link>
            <hr />
            <div className="match-bottom-container d-flex justify-content-end gap-3">
              <div
                className="match-bottom-link cursor-pointer hide-on-mobile"
                onClick={hideProfile}
              >
                {isHide ? (
                  <>
                    <p>Unhide?</p>
                    <FaRegEye className="mirror-icon match-bottom-icon" />
                  </>
                ) : (
                  <>
                    <p>Hide?</p>
                    <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
                  </>
                )}
              </div>
              <div className="bottom_details">
                <button
                  onClick={() => setShowModal(!showModal)}
                  // onClick={() => handleBlockResponse(user.userId)}
                  style={{ background: "#FF0000" }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      {/* for responsive */}

      <div className="client_top">
        <div className="client">
          <div className="img_new">
            <div style={{ position: "relative" }}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className=" matches-mobileprofileimage"
                />
              ) : (
                <img src="../default-photo-url.png" alt="Default Image" />
              )}
            </div>
          </div>
          <div className="danil-sebastine">
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              <p className="danil">{user?.name}</p>
              <p className="danil-details-mobile">{user?.profileId}</p>
              <p className="danil-details-mobile">
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              <p className="danil-details-mobile">
                {user?.qualification || "education not defined"}
                {user?.occupation
                  ? `, ${user?.occupation}`
                  : ", occupation not defined"}
                {user?.district && `, ${user?.district}`}
              </p>
            </Link>
          </div>
        </div>
        <hr></hr>
        <div className="match-bottom-container d-flex justify-content-end gap-3">
          <div
            className="match-bottom-link cursor-pointer"
            onClick={hideProfile}
          >
            <p>Hide?</p>
            <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
          </div>
          <div className="bottom_details">
            <button
              onClick={() => setShowModal(!showModal)}
              // onClick={() => handleBlockResponse(user.userId)}
              style={{ background: "#FF0000" }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      {/* <MDBModal open={showModal} centered>
        <MDBModalDialog>
          <MDBModalHeader
            className="delete-user-modal"
            style={{
              borderBottom: "0px",
              border: "10px",
              marginTop: "10rem",
            }}
          >
            <MDBModalTitle className="mx-auto">Delete Profile</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody className="delete-user-modal">
            <DeleteForm />
          </MDBModalBody>
        </MDBModalDialog>
      </MDBModal> */}
      <MDBModal show={showModal} setShow={setShowModal} tabIndex={-1}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete Profile</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setShowModal(!showModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <DeleteForm
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                otherReason={otherReason}
                setOtherReason={setOtherReason}
                error={error}
                setError={setError}
              />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn type="button" onClick={() => setShowModal(!showModal)}>
                Close
              </MDBBtn>
              <MDBBtn
                type="button"
                className="submit-user-profile-btn"
                onClick={handleDeleteConfirm}
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AgentProfileCard;
