import React, { useEffect, useState } from "react";
import api from "../../../Api/SettingApi";
import { Button } from "react-bootstrap";

const DeleteForm = ({
  selectedReason,
  setSelectedReason,
  otherReason,
  setOtherReason,
  error,
  setError,
}) => {
  const [reasons, setReason] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const fetchDeleteReasons = async () => {
      try {
        await api.getDeleteReason(token).then(({ data }) => {
          if (data.status) {
            setReason(data.data.delete_reason);
          }
        });
      } catch (error) {
        console.error("Error fetching delete reasons:", error);
      }
    };
    if (token) {
      fetchDeleteReasons();
    } else {
      console.error("Authentication token missing");
    }
  }, []);
  const handleReasonSelect = (event) => {
    setSelectedReason(event.target.value);
  };
  return (
    <>
      <p>
        We hope you have found your life partner that you're deleting this
        profile from IBG matrimony.
      </p>
      <select
        id="reason"
        name="reason"
        className="submit-delete-select-btn"
        style={{ width: 250 }}
        value={selectedReason}
        onChange={handleReasonSelect}
      >
        <option value="" disabled>
          Select a reason
        </option>
        {reasons?.map((reason) => (
          <option key={reason.id} value={reason.id}>
            {reason.delete_reason}
          </option>
        ))}
      </select>
      <div className="form-group explanation-full">
        <label htmlFor="exampleFormControlTextarea1">Other reason</label>

        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="2"
          value={otherReason}
          onChange={(e) => {
            setOtherReason(e.target.value);
            setError("");
          }}
        />
      </div>
      {/* {message && <div>{message}</div>} */}
      {error && <div className="error-deleteaccount">Error: {error}</div>}
    </>
  );
};

export default DeleteForm;
