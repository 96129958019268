import { MDBCol } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../../Api/Agent/AgentProfileApi";
import ProfileCard from "./ProfileCard";

const AgentMessagesList = () => {
  const token = localStorage.getItem("authToken");
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    getAgentProfiles();
  }, []);
  const getAgentProfiles = async () => {
    api.getAgentMyProfiles(token).then(({ data }) => {
      if (data.status) {
        setProfiles(data?.data?.profiles);
      }
    });
  };
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="notification_container">
        <h3 className="heading">Messages</h3>

        <hr />

        <div className="agent-message-inner-container">
          {profiles.length > 0 ? (
            profiles.map((profile, index) => (
              <ProfileCard profile={profile} key={index} />
            ))
          ) : (
            <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center ">
              <p style={{ color: "#640449", marginTop: "1rem" }}>
                No profiles you created
              </p>
            </div>
          )}
        </div>
      </div>
    </MDBCol>
  );
};

export default AgentMessagesList;
