import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { useNavigate } from "react-router-dom";

import { Select } from "antd";
import "antd/dist/reset.css";

const AgentAddPartnerPreferences = ({
  userData,
  listHeights,
  occupationList,
  stateAndDistricts,
  listStars,
  annualIncomeList,
  religionCaste,
  formSubmitted,
  fetchedProfileId,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [showSuccessModal, setShowSuccessModal] = useState();
  const [selectedReligions, setSelectedReligions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [selectedCastes, setSelectedCastes] = useState([]);
  const [selectedReligionIds, setSelectedReligionIds] = useState([]);
  const [selectedCasteIds, setSelectedCasteIds] = useState([]);

  useEffect(() => {
    if (selectedReligions.includes("0")) {
      setCasteOptions([]);
      setSelectedCastes([]);
      setSelectedCasteIds([0]);
      setSelectedReligionIds([0]);
    } else if (religionCaste) {
      const newCasteOptions = religionCaste
        .filter((rel) => selectedReligions.includes(rel.religion))
        .flatMap((rel) => rel.caste)
        .map((caste) => ({
          label: caste.caste,
          value: caste.id,
        }));
      setCasteOptions(newCasteOptions);
      const validCastes = newCasteOptions.map((caste) => caste.value);
      setSelectedCastes((prevCastes) =>
        prevCastes.filter((caste) => validCastes.includes(caste))
      );
      setSelectedCasteIds((prevCastes) =>
        prevCastes.filter((caste) => validCastes.includes(caste))
      );
    }
  }, [selectedReligions, religionCaste]);

  useEffect(() => {
    setPreferences((prevValue) => ({
      ...prevValue,
      profileId: fetchedProfileId,
    }));
  }, [fetchedProfileId]);

  const handleReligionChange = (values) => {
    if (values.includes("0")) {
      // Assuming "0" is the value for "Any"
      setSelectedReligions(["0"]); // Select "Any" and clear other selections
      setSelectedReligionIds([0]);
      setCasteOptions([]); // Clear caste options if "Any" is selected
      setSelectedCastes([]);
      setSelectedCasteIds([0]);
    } else {
      setSelectedReligions(values);
      const religionIdMap = religionCaste.reduce((acc, rel) => {
        acc[rel.religion] = rel.id;
        return acc;
      }, {});
      setSelectedReligionIds(values.map((religion) => religionIdMap[religion]));
    }
  };

  const handleCasteChange = (values) => {
    setSelectedCastes(values);
    setSelectedCasteIds(values);
  };

  const [preferenceMatches, setPreferenceMatches] = useState({
    min_age: 18,
    max_age: 18,
    min_height: "",
    max_height: "",
    minIncome: "",
    maxIncome: "",
    marital_status: "",
    drinking_habit: "",
    eating_habit: "",
    physical_status: "",
    smoking_habit: "",
    professional_status: "",
    professional_status_id: "",
    min_income: "",
    max_income: "",
    min_income_id: "",
    max_income_id: "",
    selectedReligions: [],
    selectedCastes: [],
  });

  const [preferences, setPreferences] = useState({
    profileId: fetchedProfileId,
    age_from: "",
    age_to: "",
    height_from: "",
    height_to: "",
    matrial_status: "",
    eating_habit: "",
    drinking_habit: "",
    smoking_habit: "",
    physical_status: "",
    income_from: "",
    income_to: "",
  });

  const [isEditingPreferenceMatches, setIsEditingPreferenceMatches] =
    useState(true);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStars, setSelectedStars] = useState([]);

  const [selectedProfessions, setSelectedProfessions] = useState([]);

  const selectedOccupation = occupationList.find(
    (occupation) =>
      occupation.occupation == userData?.partner_professional_status
  );

  const selectedAnnual_min = annualIncomeList.find(
    (annual_income) =>
      annual_income.annual_income == userData?.partner_min_income
  );
  const selectedAnnual_max = annualIncomeList.find(
    (annual_income) =>
      annual_income.annual_income == userData?.partner_max_income
  );

  useEffect(() => {
    setPreferenceMatches({
      min_age: userData?.partner_min_age,
      max_age: userData?.partner_max_age,
      min_height: userData?.partner_min_height,
      max_height: userData?.partner_max_height,
      minIncome: userData?.partner_min_income,
      maxIncome: userData?.partner_max_income,
      marital_status: userData?.partner_marital_status,
      drinking_habit: userData?.partner_drinking_habit,
      eating_habit: userData?.partner_eating_habit,
      physical_status: userData?.partner_physical_status,
      smoking_habit: userData?.partner_smoking_habit,
      professional_status: userData?.partner_professional_status,
      professional_status_id: selectedOccupation ? selectedOccupation?.id : "",
      min_income: userData?.partner_min_income,
      max_income: userData?.partner_max_income,
      min_income_id: selectedAnnual_min ? selectedAnnual_min?.id : "",
      max_income_id: selectedAnnual_max ? selectedAnnual_max?.id : "",
      selectedReligions: userData?.partner_religion || [],
      selectedCastes: userData?.partner_caste || [],
    });
    if (stateAndDistricts.length > 0) {
      setLocations([...stateAndDistricts[0]?.districts]);
    }
    if (userData?.partner_location && userData?.partner_location.length > 0) {
      const selectd = userData?.partner_location.map((item) => item.id);
      setSelectedLocations(selectd);
    }
    if (userData?.partner_star && userData?.partner_star.length > 0) {
      const selectd = userData?.partner_star.map((item) => item.id);
      setSelectedStars(selectd);
    }
    if (
      userData?.partner_professional_status &&
      userData?.partner_professional_status.length > 0
    ) {
      const selectd = userData?.partner_professional_status.map(
        (item) => item.id
      );
      setSelectedProfessions(selectd);
    }
  }, [userData]);

  const handlePreferenceChange = (e) => {
    let { name, value } = e.target;
    setPreferences((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSaveClickPartner = async () => {
    let payload = { ...preferences };
    console.log("payload", payload);
    if (payload.height_from && payload.height_from !== "any") {
      let heightFromId = await listHeights.find(
        (height) => height.height_cm === parseInt(payload.height_from)
      );
      payload.height_from = heightFromId?.id;
    }
    if (payload.height_to && payload.height_to !== "any") {
      let heightToId = await listHeights.find(
        (height) => height.height_cm === parseInt(payload.height_to)
      );
      payload.height_to = heightToId?.id;
    }
    if (payload.income_from && payload.income_from !== "any") {
      let incomeFromId = await annualIncomeList.find(
        (income) => income.annual_income === payload.income_from
      );
      payload.income_from = incomeFromId?.id;
    }
    if (payload.income_to && payload.income_to !== "any") {
      let incomeToId = await annualIncomeList.find(
        (income) => income.annual_income === payload.income_to
      );
      payload.income_to = incomeToId?.id;
    }
    payload.selectedStar = [...selectedStars];
    payload.selectedLocation = [...selectedLocations];
    payload.selectedProfession = [...selectedProfessions];
    payload.selectedReligions =
      selectedReligionIds.length > 0 ? [...selectedReligionIds] : [];
    payload.selectedCastes =
      selectedCasteIds.length > 0 ? [...selectedCasteIds] : [];

    try {
      const data = await api.updatePartnerPreference(token, payload);
      if (data.status) {
        setShowSuccessModal(true);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/agent/login");
      }
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  const handleSelectLocation = (value) => {
    const findLocation = selectedLocations.find((item) => item === value);
    let newLocation = [];
    if (findLocation) {
      const filteredLocation = selectedLocations.filter(
        (location) => location !== value
      );
      newLocation = [...filteredLocation];
    } else {
      newLocation = [...selectedLocations, value];
    }
    setSelectedLocations([...newLocation]);
  };

  const handleSelectStar = (value) => {
    const findStar = selectedStars.find((item) => item === value);
    let newStar = [];
    if (findStar) {
      const filteredStar = selectedStars.filter((star) => star !== value);
      newStar = [...filteredStar];
    } else {
      newStar = [...selectedStars, value];
    }
    setSelectedStars([...newStar]);
  };

  const handleSelectProfession = (value) => {
    const findProfession = selectedProfessions.find((item) => item === value);
    let newProfessions = [];
    if (findProfession) {
      const filteredProfessions = selectedProfessions.filter(
        (profession) => profession !== value
      );
      newProfessions = [...filteredProfessions];
    } else {
      newProfessions = [...selectedProfessions, value];
    }
    setSelectedProfessions([...newProfessions]);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal();
    navigate("/agent/all-profiles");
  };

  return (
    <>
      <p className="partner-preference-profileedit">Partner Preferences</p>
      <div className="importentdetails-editprofile">
        <MDBRow>
          <div className="user-description">
            <p className="profile-description">Basic Details</p>
          </div>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Age From</div>:
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="ageFrom"
                      name="age_from"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.age_from || ""}
                      onChange={(e) => {
                        const selectedAgeFrom =
                          e.target.value === "any"
                            ? "any"
                            : parseInt(e.target.value);
                        setPreferences((prev) => ({
                          ...prev,
                          age_from: selectedAgeFrom,
                          age_to:
                            selectedAgeFrom === "any"
                              ? "any"
                              : selectedAgeFrom >= prev.age_to
                              ? selectedAgeFrom + 1
                              : prev.age_to,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {Array.from({ length: 83 }, (_, index) => (
                        <option key={index} value={18 + index}>
                          {18 + index} years
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Age To</div>:
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="ageTo"
                      name="age_to"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.age_to || ""}
                      onChange={(e) => {
                        const selectedAgeTo =
                          e.target.value === "any"
                            ? "any"
                            : parseInt(e.target.value);
                        setPreferences((prev) => ({
                          ...prev,
                          age_to: selectedAgeTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {preferences?.age_from !== "any" &&
                        Array.from(
                          { length: 83 - (preferences?.age_from || 18) },
                          (_, index) => (
                            <option
                              key={index}
                              value={(preferences?.age_from || 18) + 1 + index}
                            >
                              {(preferences?.age_from || 18) + 1 + index} years
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Age From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.age_from || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Age To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.age_to || "Not defined"}
                  </div>
                </div>
              </>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Marital Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="maritalStatus"
                    name="matrial_status"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferences?.matrial_status}
                    onChange={handlePreferenceChange}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never Married">Never Married</option>
                    <option value="widowed">widowed</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Seperated">Seperated</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Marital Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferences?.matrial_status || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Eating Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="eating"
                    name="eating_habit"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferences?.eating_habit}
                    onChange={handlePreferenceChange}
                  >
                    <option key="" value="">
                      Select
                    </option>

                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non vegetarian">Non vegetarian</option>
                    <option value="Eggetarian">Eggetarian</option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Eating Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferences?.eating_habit || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Smoking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="smoke"
                    name="smoking_habit"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferences?.smoking_habit}
                    onChange={handlePreferenceChange}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never smokes">Never smokes</option>
                    <option value="Smoke occasionally">
                      Smoke occasionally
                    </option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Smoking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferences?.smoking_habit || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Driking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="drink"
                    name="drinking_habit"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferences?.drinking_habit}
                    onChange={handlePreferenceChange}
                  >
                    <option value="">Select</option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Drinks socially">Drinks socially</option>
                    <option value="Never drinks">Never drinks</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Driking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferences?.drinking_habit || "Not defined"}
                </div>
              </div>
            )}
          </MDBCol>

          <MDBCol size="6" sm="6" className="partner-profileedit">
            {/* HEIGHT */}
            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Height From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="height_from"
                      name="height_from"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.height_from || ""}
                      onChange={(e) => {
                        const selectedHeightFrom =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferences((prev) => ({
                          ...prev,
                          height_from: selectedHeightFrom,
                          height_to:
                            selectedHeightFrom === "any"
                              ? "any"
                              : prev.height_to !== "any" &&
                                selectedHeightFrom >= prev.height_to
                              ? ""
                              : prev.height_to,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {listHeights.map((height) => (
                        <option
                          key={height.id}
                          value={`${height.height_cm} cm`}
                        >
                          {`${height.height_cm} cm / ${height.height_feet}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Height To
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="height_to"
                      name="height_to"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.height_to || ""}
                      onChange={(e) => {
                        const selectedHeightTo =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferences((prev) => ({
                          ...prev,
                          height_to: selectedHeightTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {preferenceMatches?.min_height === "any" ? (
                        <option key="any" value="any">
                          Any
                        </option>
                      ) : (
                        <>
                          <option key="any" value="any">
                            Any
                          </option>
                          {listHeights
                            .filter(
                              (height) =>
                                parseInt(height.height_cm) >
                                parseInt(preferences?.height_from)
                            )
                            .map((height) => (
                              <option
                                key={height.id}
                                value={`${height.height_cm} cm`}
                              >
                                {`${height.height_cm} cm / ${height.height_feet}`}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Height From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.height_from || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Height To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.age_to || "Not defined"}
                  </div>
                </div>
              </>
            )}

            {/* PHYSICAL STATUS */}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Physical Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="physicalStatus"
                    name="physical_status"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferences?.physical_status}
                    onChange={handlePreferenceChange}
                  >
                    <option value="" disable>
                      Select
                    </option>

                    <option value="Normal">Normal</option>
                    <option value="Physically Challenged">
                      Physically Challenged
                    </option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Physical Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferences?.physical_status || "Not defined"}
                </div>
              </div>
            )}

            {/*  test annual income  */}

            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Annual Income From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="income_from"
                      name="income_from"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.income_from || ""}
                      onChange={(e) => {
                        const selectedHeightFrom =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferences((prev) => ({
                          ...prev,
                          income_from: selectedHeightFrom,
                          income_to:
                            selectedHeightFrom === "any"
                              ? "any"
                              : prev.income_to !== "any" &&
                                selectedHeightFrom >= prev.income_to
                              ? ""
                              : prev.income_to,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {annualIncomeList.map((height) => (
                        <option
                          key={height.id}
                          value={`${height.annual_income}`}
                        >
                          {`${height.annual_income} `}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Annual Income From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="income_to"
                      name="income_to"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferences?.income_to || ""}
                      onChange={(e) => {
                        const selectedHeightTo =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferences((prev) => ({
                          ...prev,
                          income_to: selectedHeightTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {preferences?.income_from === "any" ? (
                        <option key="any" value="any">
                          Any
                        </option>
                      ) : (
                        <>
                          <option key="any" value="any">
                            Any
                          </option>
                          {annualIncomeList
                            .filter(
                              (income) =>
                                parseInt(income.annual_income) >
                                parseInt(preferences?.income_from)
                            )
                            .map((height) => (
                              <option
                                key={height.id}
                                value={`${height.annual_income}`}
                              >
                                {`${height.annual_income}`}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Annual Income From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.income_from || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Annual Income To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferences?.income_to || "Not defined"}
                  </div>
                </div>
              </>
            )}
          </MDBCol>

          {/* Religion */}
          <MDBRow>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEditingPreferenceMatches ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Religion
                  </div>{" "}
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select religions"
                      value={selectedReligions}
                      onChange={handleReligionChange}
                      options={[
                        { label: "Any", value: "0" },
                        ...religionCaste.map((rel) => ({
                          label: rel.religion,
                          value: rel.religion,
                          disabled: selectedReligions.includes("0"),
                        })),
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="annual-income" style={{ display: "flex" }}>
                    <div style={{ flex: 1 }} className="full-bolder">
                      Religion
                    </div>
                    <div style={{ flex: 1 }}>
                      :{" "}
                      {preferenceMatches.selectedReligions.length === 0
                        ? "Not defined"
                        : preferenceMatches.selectedReligions
                            .map((rel) =>
                              typeof rel === "string" ? rel : rel.religion
                            )
                            .join(", ")}
                    </div>
                  </div>
                </>
              )}
            </MDBCol>
          </MDBRow>

          {/* Caste */}
          <MDBRow>
            <MDBCol size="8" sm="8" className="partner-profileedit">
              {isEditingPreferenceMatches
                ? selectedReligions.length > 0 &&
                  !selectedReligions.includes("0") && (
                    <div className="annual-income" style={{ display: "flex" }}>
                      <div style={{ flex: 0.3 }} className="">
                        Caste
                      </div>{" "}
                      :
                      <div className="basic-profiles" style={{ flex: 1 }}>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select castes"
                          value={selectedCastes}
                          onChange={handleCasteChange}
                          options={casteOptions}
                        />
                      </div>
                    </div>
                  )
                : preferenceMatches.selectedReligions.length > 0 &&
                  !preferenceMatches.selectedReligions
                    .map((rel) => rel.religion)
                    .includes("Any") && (
                    <div className="annual-income" style={{ display: "flex" }}>
                      <div style={{ flex: 0.3 }} className="full-bolder">
                        Caste
                      </div>
                      <div style={{ flex: 1 }}>
                        :{""}{" "}
                        {preferenceMatches.selectedCastes.length === 0
                          ? "Not defined"
                          : preferenceMatches.selectedCastes
                              .map((rel) =>
                                typeof rel === "string" ? rel : rel.caste
                              )
                              .join(", ")}
                      </div>
                    </div>
                  )}
            </MDBCol>
          </MDBRow>

          {/* LOCATION */}

          {selectedLocations.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Locations</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {locations.map((location, index) => {
                  const findLocation = selectedLocations.find(
                    (item) => item === location.dist_id
                  );
                  let isSelected = false;
                  if (findLocation) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectLocation(location?.dist_id)}
                      >
                        {location?.district}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedLocations.map((location, index) => {
                  const findLocation = locations.find(
                    (item) => item.dist_id === location
                  );
                  let isSelected = false;
                  if (findLocation) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">
                        {findLocation?.district}
                      </label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>

          {selectedStars.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Stars</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {listStars.map((star, index) => {
                  const findStar = selectedStars.find(
                    (item) => item === star.id
                  );
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectStar(star?.id)}
                      >
                        {star?.star}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedStars.map((star, index) => {
                  const findStar = listStars.find((item) => item.id === star);
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">{findStar?.star}</label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>

          {/* PROFESSIONAL STATUS */}
          {selectedProfessions.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Professional Status</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {occupationList.map((occupation, index) => {
                  const findProfession = selectedProfessions.find(
                    (item) => item === occupation.id
                  );
                  let isSelected = false;
                  if (findProfession) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectProfession(occupation?.id)}
                      >
                        {occupation.occupation}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedProfessions.map((professionId, index) => {
                  const findProfession = occupationList.find(
                    (item) => item.id === professionId
                  );
                  let isSelected = false;
                  if (findProfession) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => {}}
                      />
                      <label htmlFor={`profession_${findProfession?.id}`}>
                        {findProfession?.occupation}
                      </label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
          <div class="text-center mb-5">
            <button
              class="btn mx-auto d-block agent-profile-submit-btn"
              disabled={formSubmitted ? false : true}
              onClick={handleSaveClickPartner}
            >
              Save
            </button>
          </div>
        </MDBRow>
      </div>
      <MDBModal
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        centered
      >
        <MDBModalDialog>
          <MDBModalHeader
            className="submit-register text-center"
            style={{
              borderBottom: "0px",
              border: "10px",
              marginTop: "10rem",
            }}
          >
            <MDBModalTitle className="register-sucess-title mx-auto">
              <img
                src="../Images/reg-success.png"
                style={{
                  width: "25%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                }}
              ></img>
              <br></br>
              <p className="reg-success-message">
                Successfully updated partner preferences
              </p>
              <p className="reg-footer">Your Profile ID: {fetchedProfileId} </p>
              <MDBBtn
                color="none"
                onClick={handleCloseSuccessModal}
                className="custom-close-buttons"
              >
                OK
              </MDBBtn>
            </MDBModalTitle>
          </MDBModalHeader>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AgentAddPartnerPreferences;
