import React, { useEffect, useState } from "react";
import Nva from "./Nva";
import { Container, Form } from "react-bootstrap";
import "../style/contact.css";
import { MDBCol, MDBIcon, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import {
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import Footer from "./Footer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import api from "../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";
import Navbars from "./Navbars";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import AgentNavbar from "./Agent/AgentNavbar";
import AgentFooter from "./Agent/AgentFooter";

function Contact() {
  const location = useLocation();
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Name must contain only letters and spaces")
      .required("Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .matches(/\.com$|\.in$/, "Email must end with '.com' or '.in'")
      .required("Email is required"),
    mobile: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    message: yup.string().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    try {
      const payload = {
        name: data.name,
        email: data.email,
        phone: data.mobile,
        message: data.message,
      };

      api.postContact(payload).then(async ({ data }) => {
        if (data.status === true) {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

          reset(); // Call reset here
        } else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } catch (error) {
      // Show error toast
      toast.error("Sorry, something went wrong", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>Contact | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <div className="contact">
        <ToastContainer />
        {token ? (
          location.pathname === "/agent/contact" ? (
            <AgentNavbar />
          ) : (
            <Navbars />
          )
        ) : (
          <Nva />
        )}
        <div className="full-contact">
          <Container>
            <MDBRow className="address">
              <MDBCol size="6" sm="6" className="contact-address">
                <h2 className="contact-heading">Contact us</h2>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group controlId="name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="SelectBoxs-contact"
                      style={{ width: 500 }}
                      {...register("name")}
                    />
                    {errors.name && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.name.message}
                      </p>
                    )}
                  </Form.Group>
                  <br />

                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      className="SelectBoxs-contact"
                      style={{ width: 500 }}
                      {...register("email")}
                    />
                    {errors.email && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.email.message}
                      </p>
                    )}
                  </Form.Group>
                  <br />

                  <Form.Group controlId="mobile">
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      className="SelectBoxs-contact"
                      style={{ width: 500 }}
                      {...register("mobile")}
                    />
                    {errors.mobile && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.mobile.message}
                      </p>
                    )}
                  </Form.Group>
                  <br />

                  <Form.Group controlId="message">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      className="SelectBoxs-contact-message"
                      style={{ width: 500 }}
                      {...register("message")}
                    />
                    {errors.message && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.message.message}
                      </p>
                    )}
                  </Form.Group>

                  <MDBBtn type="submit" block className="my-4 contact-submit">
                    Send
                  </MDBBtn>
                </Form>
              </MDBCol>
              <MDBCol size="6" sm="6" className="our-contact">
                <h1>We are at your service</h1>
                <p>
                  Our customer service team will help you with all your queries.
                  We also have an FAQ (Frequently Asked Questions) section to
                  address commonly asked questions. If you are still not happy,
                  contact us directly.
                </p>
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p className="text-reset">
                  <MDBIcon fas icon="map-marker-alt" />
                  Indian Brides & Grooms Matrimony <br /> Door No.CC 35/1398C3,
                  2nd Floor,City Tower, <br />
                  MKK Nair Road, Palarivattom, Kochi- 682025
                </p>

                <p className="text-reset conact-mail">
                  <MDBIcon fas icon="phone-alt" />
                  <a href="tel:+919539990515">+91 9539990515 </a>
                </p>
                <p className="text-reset conact-mail">
                  <MDBIcon fas icon="globe-asia" />
                  <a href="mailto:noreply@ibgmatrimony.com">
                    noreply@ibgmatrimony.com
                  </a>
                </p>
              </MDBCol>
            </MDBRow>
          </Container>
        </div>
        {location.pathname === "/agent/contact" ? <AgentFooter /> : <Footer />}
      </div>
    </>
  );
}

export default Contact;
