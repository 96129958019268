import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import PartnerPreferences from "./PartnerPreferences";
import MainAgentUserProfile from "./MainAgentUserProfile";
import EditAgentUserProfile from "./EditAgentUserProfile";
import EditAgentPartnerPreference from "./EditAgentPartnerPreferences";

const ProfileDetails = ({
  profileData,
  partnerPreferenceData,
  setSavedEditProfileDetails,
}) => {
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isEditPartnerPreference, setIsEditPartnerPreference] = useState(false);

  return (
    <>
      {isEditProfile ? (
        <EditAgentUserProfile
          profileData={profileData}
          setIsEditProfile={setIsEditProfile}
          setSavedEditProfileDetails={setSavedEditProfileDetails}
        />
      ) : (
        <MainAgentUserProfile
          profileData={profileData}
          setIsEditProfile={setIsEditProfile}
        />
      )}
      {isEditPartnerPreference ? (
        <EditAgentPartnerPreference
          partnerPreferenceData={partnerPreferenceData}
          setIsEditPartnerPreference={setIsEditPartnerPreference}
          setSavedEditProfileDetails={setSavedEditProfileDetails}
        />
      ) : (
        <PartnerPreferences
          partnerPreferenceData={partnerPreferenceData}
          setIsEditPartnerPreference={setIsEditPartnerPreference}
        />
      )}
    </>
  );
};

export default ProfileDetails;
