import React, { useEffect, useState, useRef } from "react";
import "../style/Chat.css";
import { firebaseApp } from "../firebase/firebase";
import { v4 as uuid } from "uuid";
import { getProfileDetails } from "../redux/features/profile";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { PiGearLight, PiXLight, PiPaperPlaneRight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Chat = ({
  chatData,
  onClose,
  from,
  onBlockUser,
  isAgent,
  profileDetails,
}) => {
  const getUserDetails = useSelector(getProfileDetails);
  const [settingsDropShow, setSettingsDropShow] = useState(false);
  const combinedId =
    (isAgent ? profileDetails?.profileId : getUserDetails?.profile_id) >
    chatData?.userId
      ? `${chatData?.userId}-${
          isAgent ? profileDetails?.profileId : getUserDetails?.profile_id
        }`
      : `${isAgent ? profileDetails?.profileId : getUserDetails?.profile_id}-${
          chatData?.userId
        }`;
  const firebaseDB = getFirestore(firebaseApp);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isUserOnline, setIsUserOnline] = useState(false);

  const settingsDropRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const handleBodyClick = (event) => {
    if (
      settingsDropRef.current &&
      !settingsDropRef.current.contains(event.target)
    ) {
      setSettingsDropShow(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.body.addEventListener("mousedown", handleBodyClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("mousedown", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    const unSub = onSnapshot(
      doc(firebaseDB, "chats", combinedId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().messages.length > 0) {
            setMessages(docData.data().messages);
          } else {
            setMessageInput(
              "Hi, I like your profile. If you are interested, please send a message."
            );
          }
        } else {
          setMessageInput(
            "Hi, I like your profile. If you are interested, please send a message."
          );
        }
      }
    );

    const onSubLast = onSnapshot(
      query(
        collection(firebaseDB, "lastMessages"),
        where(
          "receiverId",
          "==",
          isAgent ? profileDetails?.profileId : getUserDetails?.profile_id
        ),
        where("isViewed", "==", false)
      ),
      (querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const docRef = doc.ref;
          await updateDoc(docRef, {
            isViewed: true,
          });
        });
      }
    );

    const onGetOnlineStatus = onSnapshot(
      doc(firebaseDB, "users", chatData?.userId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().status === "ONLINE") {
            setIsUserOnline(true);
          } else {
            setIsUserOnline(false);
          }
        } else {
          setIsUserOnline(false);
        }
      }
    );

    return () => {
      unSub();
      onSubLast();
      onGetOnlineStatus();
    };
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    const trimmedMessage = messageInput.trim();

    if (!trimmedMessage) {
      console.log("Message is empty. Please enter a message.");
      return;
    }

    try {
      const newMessageId = await uuid();
      const currentTimeInUTC = new Date().toUTCString();
      // Check if the document already exists
      const lastMessagesDocRef = doc(firebaseDB, "lastMessages", combinedId);
      const lastMessagesDocSnap = await getDoc(lastMessagesDocRef);
      // const docRef = doc(firebaseDB, "chats", combinedId);
      // const docSnap = await getDoc(docRef);

      setMessageInput("");

      if (!lastMessagesDocSnap.exists()) {
        await setDoc(doc(firebaseDB, "lastMessages", combinedId), {
          message: trimmedMessage,
          senderId: isAgent
            ? profileDetails?.profileId
            : getUserDetails?.profile_id,
          senderName: isAgent ? profileDetails?.name : getUserDetails?.name,
          senderImage: isAgent
            ? profileDetails?.image
            : getUserDetails?.profile_image,
          receiverId: chatData?.userId,
          receiverName: chatData?.name,
          receiverImage: chatData?.image,
          status: "REQUESTED",
          isViewed: false,
          time: currentTimeInUTC,
        });
        //create new
        await setDoc(doc(firebaseDB, "chats", combinedId), {
          messages: [
            {
              id: newMessageId,
              message: trimmedMessage,
              senderId: isAgent
                ? profileDetails?.profileId
                : getUserDetails?.profile_id,
              time: currentTimeInUTC,
            },
          ],
        });
      } else {
        if (from === "REQUESTS") {
          console.log("userDataa", chatData);
          await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
            message: trimmedMessage,
            senderId: isAgent
              ? profileDetails?.profileId
              : getUserDetails?.profile_id,
            senderName: isAgent ? profileDetails?.name : getUserDetails?.name,
            senderImage: isAgent
              ? profileDetails?.image
              : getUserDetails?.profile_image,
            receiverId: chatData?.userId,
            receiverName: chatData?.name,
            receiverImage: chatData?.image,
            status: "ACCEPTED",
            isViewed: false,
            time: currentTimeInUTC,
          });
        } else {
          if (lastMessagesDocSnap.data().status === "REJECTED") {
            await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
              message: trimmedMessage,
              senderId: isAgent
                ? profileDetails?.profileId
                : getUserDetails?.profile_id,
              senderName: isAgent ? profileDetails?.name : getUserDetails?.name,
              senderImage: isAgent
                ? profileDetails?.image
                : getUserDetails?.profile_image,
              receiverId: chatData?.userId,
              receiverName: chatData?.name,
              receiverImage: chatData?.image,
              status: "REQUESTED",
              isViewed: false,
              time: currentTimeInUTC,
            });
          } else {
            await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
              message: trimmedMessage,
              senderId: isAgent
                ? profileDetails?.profileId
                : getUserDetails?.profile_id,
              senderName: isAgent ? profileDetails?.name : getUserDetails?.name,
              senderImage: isAgent
                ? profileDetails?.image
                : getUserDetails?.profile_image,
              receiverId: chatData?.userId,
              receiverName: chatData?.name,
              receiverImage: chatData?.image,
              isViewed: false,
              time: currentTimeInUTC,
            });
          }
        }
        await updateDoc(doc(firebaseDB, "chats", combinedId), {
          messages: arrayUnion({
            id: newMessageId,
            message: trimmedMessage,
            senderId: isAgent
              ? profileDetails?.profileId
              : getUserDetails?.profile_id,
            time: currentTimeInUTC,
          }),
        });
      }
    } catch (err) {
      console.log("Error sending message:", err);
    }
  };

  const handleCloseChat = () => {
    onClose();
  };

  //new//

  return (
    <div className="chat-popup">
      <div className="messenger-header">
        <div className="d-flex align-items-center p-[20px]">
          <div className="messenger-img rounded-circle">
            <img
              className="rounded-circle"
              src={
                chatData?.image
                  ? chatData?.image
                  : "/Images/default-photo-url.jpg"
              }
              width={57}
              height={57}
              alt="msg-img"
            ></img>
          </div>
          <div className="user-info">
            <h4 className="text-capitalize">{chatData?.name}</h4>
            <p className="user-online-text">
              {isUserOnline ? (
                <>
                  <span className="user-dot user-dot-online"></span> Online
                </>
              ) : (
                <>
                  <span className="user-dot user-dot-offline"></span> Offline
                </>
              )}
            </p>
          </div>
        </div>
        <div className="d-flex gap-3" ref={settingsDropRef}>
          <div>
            <PiXLight className="msg-icons" onClick={handleCloseChat} />
          </div>
          <div className="msg-settings">
            <PiGearLight
              className="msg-icons msg-gear"
              onClick={() => setSettingsDropShow(!settingsDropShow)}
            />
            <div
              className={`msg-list ${settingsDropShow ? "d-block" : "d-none"}`}
            >
              <div className="pt-2 pb-1 px-3">
                <Link
                  to={`${
                    isAgent
                      ? `/agent/all-profiles/${profileDetails?.profileId}/${chatData?.userId}`
                      : `/profile-details/${chatData?.userId}`
                  }`}
                >
                  <p>Profile</p>
                </Link>
              </div>
              <div
                className="pt-1 pb-2 px-3 cursor-pointer"
                onClick={onBlockUser}
              >
                <p>Block</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chat-messenger" ref={messagesContainerRef}>
        {Array.isArray(messages) && messages.length > 0 ? (
          messages.map((msg, index) => (
            <div
              className={`d-flex gap-2 align-items-center p-[20px] mb-2 ${
                (isAgent
                  ? profileDetails?.profileId
                  : getUserDetails?.profile_id) === msg?.senderId
                  ? "sent-message"
                  : "received-message"
              }`}
              key={index}
            >
              <div className="rounded-circle">
                <img
                  className="rounded-circle"
                  src={
                    (isAgent
                      ? profileDetails?.profileId
                      : getUserDetails?.profile_id) === msg?.senderId
                      ? (
                          isAgent
                            ? profileDetails?.image
                            : getUserDetails?.profile_image
                        )
                        ? isAgent
                          ? profileDetails?.image
                          : getUserDetails?.profile_image
                        : "/Images/default-photo-url.jpg"
                      : chatData?.image
                      ? chatData?.image
                      : "/Images/default-photo-url.jpg"
                  }
                  width={30}
                  height={30}
                  alt="msg-img"
                ></img>
              </div>
              <div className="py-1 px-2 rounded-3">
                <p>{msg.message}</p>
              </div>
            </div>
          ))
        ) : (
          <p id="previousChat">Start communication</p>
        )}
      </div>
      <div className="p-3 border-top mt-1">
        <div className="d-flex row mx-md-2 mx-1 msg-input">
          <input
            type="text"
            className="col-10"
            placeholder="Type here......"
            value={messageInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button
            className="btn col-2"
            type="submit"
            onClick={handleSendMessage}
          >
            <PiPaperPlaneRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
