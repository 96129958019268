import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const AgentEditProfessionalDetails = ({
  userData,
  registerData,
  setRegisterData,
  handleRegisterData,
  educationList,
  employedInList,
  occupationList,
  annualIncomeList,
  register,
}) => {
  const [courseList, setCourseList] = useState([]);
  const [professional_details, setProfessionalDetails] = useState({
    education_id: "",
    education: "",
    course: "",
    course_id: "",
    qualification: "",
    employed_in_id: "",
    employed_in: "",
    occupation: "",
    occupation_id: "",
    annual_income: "",
    annual_income_id: "",
  });
  useEffect(() => {
    const selectedEducation = educationList.find(
      (education) => education.id == registerData?.education_main
    );

    if (selectedEducation) {
      setCourseList([...selectedEducation.sub_qualification]);
    }

  }, [registerData, educationList]);

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Professional Details</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="full-bolder">
            Education
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="education"
              name="education_main"
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.education_main}
              {...register("education_main")}
              onChange={(e) => {
                const selectedEducationId = e.target.value;

                if (selectedEducationId) {
                  const selectedEducation = educationList.find(
                    (education) => education.id == selectedEducationId
                  );
                  setRegisterData((prevValue) => ({
                    ...prevValue,
                    education_main: selectedEducationId,
                    education_sub: "",
                  }));
                  setCourseList([...selectedEducation.sub_qualification]);
                } else {
                  setRegisterData((prevValue) => ({
                    ...prevValue,
                    education_main: selectedEducationId,
                    education_sub: "",
                  }));
                  setCourseList([]);
                }
              }}
            >
              <option key="" value="">
                Select
              </option>
              {educationList.map((education) => (
                <option key={education.id} value={education.id}>
                  {education.main_qualification}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="full-bolder">
            Qualification
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="course"
              name="education_sub"
              className="SelectBoxs-searchpage-profileedit"
              {...register("education_sub")}
              value={registerData?.education_sub}
              onChange={handleRegisterData}
            >
              <option key="" value="">
                Select
              </option>
              {courseList.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.sub_qualification}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="full-bolder">
            Employed in
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="employedin"
              name="employed_in"
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.employed_in}
              {...register("employed_in")}
              onChange={handleRegisterData}
            >
              <option value="">Select</option>

              {/* Remove the selected attribute from here */}
              {employedInList.map((employeed_in) => (
                <option key={employeed_in.id} value={employeed_in?.id}>
                  {employeed_in?.employed_in}
                </option>
              ))}
            </select>
          </div>
        </div>
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="full-bolder">
            Occupation
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="occupation"
              name="occupation"
              className="SelectBoxs-searchpage-profileedit"
              {...register("occupation")}
              style={{ width: 350 }}
              value={registerData?.occupation}
              onChange={handleRegisterData}
            >
              <option key="" value="">
                Select
              </option>
              {occupationList.map((occupation) => (
                <option key={occupation?.id} value={occupation?.id}>
                  {occupation.occupation}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="full-bolder">
            Annual income
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="annualincome"
              name="annual_income"
              className="SelectBoxs-searchpage-profileedit"
              {...register("annual_income")}
              value={registerData?.annual_income}
              onChange={handleRegisterData}
            >
              <option key="" value="">
                Select
              </option>
              {annualIncomeList.map((annual_income) => (
                <option key={annual_income.id} value={annual_income.id}>
                  {annual_income.annual_income}
                </option>
              ))}
            </select>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default AgentEditProfessionalDetails;
