import {
  and,
  collection,
  getFirestore,
  onSnapshot,
  or,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firebaseApp } from "../../firebase/firebase";
import Chat from "../Chat";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";
import { Link, useParams } from "react-router-dom";

const History = ({ setChatData, from }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const { profileId } = useParams();
  const userDetails = useSelector(getProfileDetails);
  const getUserDetails =
    from === "agent" ? { profile_id: profileId } : userDetails;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const unSub = onSnapshot(
      query(
        collection(firebaseDB, "lastMessages"),
        and(
          where("status", "==", "ACCEPTED"),
          or(
            where("receiverId", "==", getUserDetails?.profile_id),
            where("senderId", "==", getUserDetails?.profile_id)
          )
        )
      ),
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => new Date(b.time) - new Date(a.time));
        setMessages(data);
      }
    );

    return () => {
      unSub();
    };
  }, []);

  const onReply = (user_id, user_name, user_image) => {
    setChatData({
      status: true,
      userId: user_id,
      name: user_name,
      image: user_image,
      from: "",
    });
  };

  return (
    <>
      {messages.length > 0 ? (
        <>
          {messages.map((msg, index) => (
            <MDBRow className="client-format" key={index}>
              <MDBCol size="6" sm="3" className="client-images">
                <Link
                  to={`${
                    from === "agent"
                      ? `/agent/all-profiles/${profileId}/${
                          profileId === msg?.senderId
                            ? msg?.receiverId
                            : msg?.senderId
                        }`
                      : `/profile-details/${
                          getUserDetails?.profile_id === msg?.senderId
                            ? msg?.receiverId
                            : msg?.receiverId
                        }`
                  }`}
                >
                  <img
                    src={
                      getUserDetails?.profile_id === msg?.senderId
                        ? msg?.receiverImage
                          ? msg?.receiverImage
                          : "/default-photo-url.png"
                        : msg?.senderImage
                        ? msg?.senderImage
                        : "/default-photo-url.png"
                    }
                    width={150}
                    alt={`msgimg${index}`}
                  />
                </Link>
              </MDBCol>
              <MDBCol size="6" sm="9" className="client-alldetails">
                <p>
                  {getUserDetails?.profile_id === msg?.senderId ? (
                    <span className="text-capitalize">
                      {msg?.receiverName} (
                      <Link
                        to={`${
                          from === "agent"
                            ? `/agent/all-profiles/${profileId}/${msg?.receiverId}`
                            : `/profile-details/${msg?.receiverId}`
                        }`}
                        style={{ color: "#F39200" }}
                      >
                        {msg?.receiverId}
                      </Link>
                      )
                    </span>
                  ) : (
                    <>
                      <span className="text-capitalize">
                        {msg?.senderName} (
                        <Link
                          to={`${
                            from === "agent"
                              ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                              : `/profile-details/${msg?.senderId}`
                          }`}
                          style={{ color: "#F39200" }}
                        >
                          {msg?.senderId}
                        </Link>
                        )
                      </span>{" "}
                      sent you a message!
                    </>
                  )}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
                <hr />
                <p className="client-intraction">
                  {getUserDetails?.profile_id === msg?.senderId
                    ? "Do you want to continue to the message?"
                    : "Do you want to reply to the message?"}
                  <button
                    className="client-replay"
                    onClick={() =>
                      onReply(
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverId
                          : msg?.senderId,
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverName
                          : msg?.senderName,
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverImage
                          : msg?.senderImage
                      )
                    }
                  >
                    {getUserDetails?.profile_id === msg?.senderId
                      ? "Message"
                      : "Reply"}
                  </button>
                </p>
              </MDBCol>
            </MDBRow>
          ))}
        </>
      ) : (
        <p className="nomessages">You don't have any new messages.</p>
      )}
      {messages.map((msg, index) => (
        <div className="intrest_container mobile_intrest" key={index}>
          <div className="mobleft">
            <div className="left_img">
              <div className="img_new">
                <div className="message-mobileresponse">
                  <div style={{ position: "relative" }}>
                    <Link
                      to={`${
                        from === "agent"
                          ? `/agent/all-profiles/${profileId}/${
                              profileId === msg?.senderId
                                ? msg?.receiverId
                                : msg?.senderId
                            }`
                          : `/profile-details/${
                              getUserDetails?.profile_id === msg?.senderId
                                ? msg?.receiverId
                                : msg?.receiverId
                            }`
                      }`}
                    >
                      <img
                        src={
                          getUserDetails?.profile_id === msg?.senderId
                            ? msg?.receiverImage
                              ? msg?.receiverImage
                              : "default-photo-url.png"
                            : msg?.senderImage
                            ? msg?.senderImage
                            : "default-photo-url.png"
                        }
                        width={150}
                        alt={`msgimg${index}`}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="message-profiles">
              <div className="right_details" style={{ color: "#F39200" }}>
                <p>
                  {getUserDetails?.profile_id === msg?.senderId ? (
                    <span className="text-capitalize">
                      {msg?.receiverName} (
                      <Link
                        to={`${
                          from === "agent"
                            ? `/agent/all-profiles/${profileId}/${msg?.receiverId}`
                            : `/profile-details/${msg?.receiverId}`
                        }`}
                        style={{ color: "#F39200" }}
                      >
                        {msg?.receiverId}
                      </Link>
                      )
                    </span>
                  ) : (
                    <>
                      <span className="text-capitalize">
                        {msg?.senderName} (
                        <Link
                          to={`${
                            from === "agent"
                              ? `/agent/all-profiles/${profileId}/${msg?.senderId}`
                              : `/profile-details/${msg?.senderId}`
                          }`}
                          style={{ color: "#F39200" }}
                        >
                          {msg?.senderId}
                        </Link>
                        )
                      </span>{" "}
                      sent you a message!
                    </>
                  )}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
              </div>
            </div>
          </div>
          <hr></hr>
          <p className="client-intraction">
            {getUserDetails?.profile_id === msg?.senderId
              ? "Do you want to continue to the message?"
              : "Do you want to reply to the message?"}
            <button
              className="client-replay"
              onClick={() =>
                onReply(
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverId
                    : msg?.senderId,
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverName
                    : msg?.senderName,
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverImage
                    : msg?.senderImage
                )
              }
            >
              {getUserDetails?.profile_id === msg?.senderId
                ? "Message"
                : "Reply"}
            </button>
          </p>
        </div>
      ))}
    </>
  );
};

export default History;
