import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { firebaseApp } from "../../../../firebase/firebase";

const ProfileCard = ({ profile }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const [messageCount, setMessageCount] = useState(0);
  useEffect(() => {
    return () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        onSnapshot(
          query(
            collection(firebaseDB, "lastMessages"),
            where("receiverId", "==", profile?.profileId),
            where("isViewed", "==", false)
          ),
          (querySnapShot) => {
            const data = querySnapShot.docs.map((doc) => doc.data());
            const notRejectedData = data.filter(
              (da) => da.status !== "REJECTED"
            );
            setMessageCount(notRejectedData.length);
          }
        );
      }
    };
  }, []);
  return (
    <div className="notification_box">
      <div className="d-flex not-flex">
        <div>
          <div className="image_not">
            <Link to={`${profile?.profileId}`}>
              <img
                className="notimage"
                src={profile?.image ? profile?.image : "/default-photo-url.png"}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="content_noti d-flex flex-column justify-content-center">
          <div className="">
            <Link to={`${profile?.profileId}`}>
              <h3>{profile?.name} </h3>
            </Link>
            <Link to={`${profile?.profileId}`}>
              <h6>{profile?.profileId}</h6>
            </Link>
            <Link to={`${profile?.profileId}`}>
              <button className="text-capitalize message-list-btn">
                Click to View Inbox <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
        {messageCount > 0 && (
          <div className="d-flex flex-column justify-content-center">
            <div className="message-count-numbers">{messageCount}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
