import { MDBRow } from "mdb-react-ui-kit";
import React from "react";

const AgentEditHobbies = ({ registerData, setRegisterData, hobbies }) => {
  const handleSelectHobby = (value) => {
    const findHobby = registerData?.hobbies.find((item) => item === value);
    let newHobbies = [];
    if (findHobby) {
      const filteredHobbies = registerData?.hobbies.filter(
        (hobby) => hobby !== value
      );
      newHobbies = [...filteredHobbies];
    } else {
      newHobbies = [...registerData?.hobbies, value];
    }
    setRegisterData((prevValue) => ({
      ...prevValue,
      hobbies: [...newHobbies],
    }));
  };
  return (
    <MDBRow>
      <p className="profile-description">Interest & Hobbies</p>
      <ul className="ks-cboxtags">
        {hobbies.map((hobby, index) => {
          const findHobby = registerData?.hobbies.find(
            (item) => item.id === hobby.id
          );
          let isSelected = false;
          if (findHobby) {
            isSelected = true;
          }
          return (
            <li key={index}>
              <input type="checkbox" checked={isSelected} onChange={() => {}} />
              <label
                htmlFor="checkboxOne"
                onClick={() => handleSelectHobby(hobby)}
              >
                {hobby?.sports}
              </label>
            </li>
          );
        })}
      </ul>
    </MDBRow>
  );
};

export default AgentEditHobbies;
