import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import MoreProfiles from "./MoreProfiles";
import ImageModal from "../../../EditProfile/ImageModal";
import SimilarProfiles from "../../../EditProfile/SimilarProfiles";

const AgentProfileLeftDetails = ({
  profileData,
  profileImages,
  moreProfiles,
  from,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const openModal = (index) => {
    if (profileImages.length > 0) {
      setSelectedImageIndex(index);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <MDBCol size="6" sm="3" className="profiledetails-full">
      <MDBContainer>
        <MDBRow>
          <Carousel showArrows={true} className="similar-photos">
            {profileImages?.length > 0 ? (
              profileImages.map((prfImg, index) => (
                <div
                  className="editprofile-data img-fluid"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => openModal(index)}
                >
                  <img
                    src={`${prfImg?.profileImage}`}
                    alt="profile-img"
                    className="img-fluid searchemployee upload upload-image"
                  />
                </div>
              ))
            ) : (
              <div className="editprofile-data img-fluid ">
                <img
                  src="/default-photo-url.png"
                  className="img-fluid searchemployee"
                  alt="Default"
                  onClick={() => openModal(0)}
                />
              </div>
            )}
          </Carousel>
          {from === "user" ? (
            <SimilarProfiles profiles={moreProfiles} isAgent={true} />
          ) : (
            <MoreProfiles profiles={moreProfiles} />
          )}
        </MDBRow>
      </MDBContainer>
      {modalOpen && (
        <ImageModal onClose={closeModal}>
          <p style={{ color: "white" }}>
            {profileData?.name} ({profileData?.profileId})
          </p>

          <Carousel selectedItem={selectedImageIndex}>
            {profileImages.map((image, index) => (
              <div key={index} className="d-flex align-items-center">
                <img src={image} alt={`Image ${index}`} />
              </div>
            ))}
          </Carousel>
        </ImageModal>
      )}
      {modalOpen && <div className="modal-backdrop fade show"></div>}
    </MDBCol>
  );
};

export default AgentProfileLeftDetails;
