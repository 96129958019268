import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const PartnerPreferences = ({
  partnerPreferenceData,
  setIsEditPartnerPreference,
}) => {
  const [preference, setPreference] = useState({});
  const [partnerStar, setPartnerStar] = useState([]);
  const [partnerProfessionals, setPartnerProfessional] = useState([]);
  const [partnerLocations, setPartnerLocations] = useState([]);

  useEffect(() => {
    setPreference(partnerPreferenceData);
    setPartnerStar(partnerPreferenceData?.partnerStar);
    setPartnerProfessional(partnerPreferenceData?.partnerProfessionalStatus);
    setPartnerLocations(partnerPreferenceData?.partnerLocation);
  }, [partnerPreferenceData]);
  return (
    <>
      <h4 style={{ textAlign: "center", margin: "20px 0px" }}>
        Partner Preferences
      </h4>
      <div className="profile-editprofile">
        <div className="profile-editprofile-title ">
          <p className="profile-description-profiledetails">Basic Details</p>
          <button onClick={() => setIsEditPartnerPreference(true)}>
            <MDBIcon far icon="edit" /> Edit
          </button>
        </div>
        <MDBRow>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Age From
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMinAge || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Age To
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMaxAge || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Marital Status
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMaritalStatus || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Eating Habits
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerEatingHabit || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Smoking Habits
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerSmokingHabit || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Driking Habits
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerDrinkingHabit || "Not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Height From
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMinHeight || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Height To
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMaxHeight || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Physical Status
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partner_physical_status || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Annual Income From
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMinIncome || "Not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Annual Income To
              </div>
              <div style={{ flex: 1 }}>
                :{""} {preference?.partnerMaxIncome || "Not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBRow>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Religion
                </div>
                <div style={{ flex: 1 }}>
                  :{" "}
                  {preference?.partnerReligion &&
                  preference?.partnerReligion.length === 0
                    ? "Not defined"
                    : preference?.partnerReligion &&
                      preference.partnerReligion
                        .map((rel) =>
                          typeof rel === "string" ? rel : rel.religion
                        )
                        .join(", ")}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="8" sm="8" className="partner-profileedit">
              {preference?.partnerReligion &&
                preference?.partnerReligion.length > 0 &&
                !preference?.partnerReligion
                  .map((rel) => rel.religion)
                  .includes("Any") && (
                  <div className="annual-income" style={{ display: "flex" }}>
                    <div style={{ flex: 0.3 }} className="full-bolder">
                      Caste
                    </div>
                    <div style={{ flex: 1 }}>
                      :{""}{" "}
                      {preference?.partnerCaste &&
                      preference?.partnerCaste.length === 0
                        ? "Not defined"
                        : preference?.partnerCaste &&
                          preference.partnerCaste
                            .map((rel) =>
                              typeof rel === "string" ? rel : rel.caste
                            )
                            .join(", ")}
                    </div>
                  </div>
                )}
            </MDBCol>
          </MDBRow>
          {partnerLocations.length > 0 && (
            <>
              <p className="profile-description">Locations</p>
              <ul className="ks-cboxtags">
                {partnerLocations.map((location, index) => {
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">{location?.location}</label>
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          <p className="profile-description">Stars</p>
          <ul className="ks-cboxtags">
            {partnerStar.map((star, index) => {
              return (
                <li key={index}>
                  <input type="checkbox" checked={true} onChange={() => {}} />
                  <label htmlFor="checkboxOne">{star?.star}</label>
                </li>
              );
            })}
          </ul>

          <p className="profile-description">Professional Status</p>
          <ul className="ks-cboxtags">
            {partnerProfessionals.map((occupation, index) => {
              return (
                <li key={index}>
                  <input type="checkbox" checked={true} onChange={() => {}} />
                  <label htmlFor="checkboxOne">{occupation.profession}</label>
                </li>
              );
            })}
          </ul>
        </MDBRow>
      </div>
    </>
  );
};

export default PartnerPreferences;
