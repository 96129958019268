import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import {
  FaEye,
  FaRegEyeSlash,
  FaRegThumbsUp,
  FaThumbsUp,
} from "react-icons/fa6";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { RxChatBubble } from "react-icons/rx";
import { TbClipboardList } from "react-icons/tb";
import ProfileDescriptions from "../../../ProfileDetails/ProfileDescriptions";
import PartnerPreference from "../../../ProfileDetails/PartnerPreference";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { firebaseApp } from "../../../../firebase/firebase";
import api from "../../../../Api/Agent/AgentProfileApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Chat from "../../../Chat";

const AgentUserProfileRightSide = ({
  profileData,
  partnerPreference,
  openChat,
  setOpenChat
}) => {
  const token = localStorage.getItem("authToken");
  const { profileId, userId } = useParams();
  const firebaseDB = getFirestore(firebaseApp);
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [shortListed, setShortList] = useState(false);
  const [isProfileLiked, setIsProfileLiked] = useState(false);
  const [isAccept, setIsAccept] = useState("");
  const [isHideProfile, setIsHideProfile] = useState(false);
  useEffect(() => {
    setIsProfileLiked(profileData?.isLiked);
    setShortList(profileData?.isShortlisted);
    setIsHideProfile(profileData?.isHide);
    if (profileData && profileData.isAccepted !== undefined) {
      setIsAccept(profileData.isAccepted);
    }
    const onGetOnlineStatus = onSnapshot(
      doc(firebaseDB, "users", userId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().status === "ONLINE") {
            setIsUserOnline(true);
          }
        } else {
          setIsUserOnline(false);
        }
      }
    );
    return () => {
      if (userId) {
        onGetOnlineStatus();
      }
    };
  }, [profileData]);
  const handleShortList = () => {
    let payload = {
      otherProfileId: profileData?.profile_id,
    };
    api.shortlistAgentProfile(profileId, token, payload).then(({ data }) => {
      if (data?.status) {
        setShortList(!shortListed);
      }
    });
  };
  const handleLike = () => {
    if (!isAccept) {
      handleLikeProfile();
    }
  };
  const handleLikeProfile = () => {
    let payload = {
      otherProfileId: profileData?.profile_id,
    };
    api.interestAgentProfile(profileId, token, payload).then(({ data }) => {
      if (data?.status) {
        setIsProfileLiked(!isProfileLiked);
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  const handleHideProfile = () => {
    let payload = {
      hideCode: profileData?.profile_id,
    };
    api.hideAgentProfile(profileId, payload, token).then(({ data }) => {
      if (data.status) {
        setIsHideProfile(!isHideProfile);
      }
    });
  };
  return (
    <>
      <MDBCol size="6" sm="9" className="profiledetails-full">
        <p className="danils">
          {profileData?.name}
          <span className="online">
            <img src="/Images/online.png" alt="img-tick"></img>
          </span>
          {isUserOnline ? (
            <span className="active-online">
              <span className="dot"></span> Online
            </span>
          ) : (
            <span className="active-offline">
              <span className="active-offline-dot"></span> Offline
            </span>
          )}
        </p>
        <MDBRow className="profile-row">
          <MDBCol>
            <p className="mother-tongue">
              {profileData?.age} Yrs | {profileData?.profile_id}
            </p>
            <p className="mother-tongue">
              {profileData?.height_cm
                ? `${profileData?.height_cm} cm / ${profileData?.height_feet}`
                : "height not defined"}
            </p>
            <p className="mother-tongue">
              {profileData?.religion || "Religion not defined"} /{" "}
              {profileData?.caste || "Caste not defined"}
            </p>
            <p className="mother-tongue">
              Occupation in {profileData?.occupation || "Not defined"}
            </p>
          </MDBCol>
          <MDBCol>
            <p className="mother-tongue">
              Star is {profileData?.star || "Not defined"}
            </p>

            <p className="mother-tongue">
              Lives in {profileData?.district || "Not defined"}
            </p>
            <p className="mother-tongue">
              Studied {profileData?.qualification_main || "Not defined"}
            </p>
          </MDBCol>
        </MDBRow>
        <div className="short-links">
          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setOpenChat(!openChat);
            }}
          >
            <p>Chat</p>
            <RxChatBubble className="mirror-icon match-bottom-icon" />
          </div>

          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleShortList();
            }}
          >
            {shortListed ? (
              <>
                <p>Shortlisted</p>
                <HiClipboardDocumentCheck className="match-bottom-icon" />
              </>
            ) : (
              <>
                <p>Shortlist?</p>
                <TbClipboardList className="match-bottom-icon" />
              </>
            )}
          </div>

          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleHideProfile();
            }}
          >
            {isHideProfile ? (
              <>
                <p>Unhide?</p>
                <FaEye className="mirror-icon match-bottom-icon" />
              </>
            ) : (
              <>
                <p>Hide?</p>
                <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
              </>
            )}
          </div>

          <div
            className={`match-bottom-link ${
              !profileData?.isAccepted && " cursor-pointer"
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleLike();
            }}
          >
            {isProfileLiked ? (
              <>
                <p>Liked</p>
                <FaThumbsUp className="match-bottom-icon" />
              </>
            ) : (
              <>
                <p>Like His Profile?</p>
                <FaRegThumbsUp className="match-bottom-icon" />
              </>
            )}
          </div>
        </div>
        <ProfileDescriptions userData={profileData} />
        <PartnerPreference
          userData={profileData}
          partnerPreference={partnerPreference}
        />
      </MDBCol>
    </>
  );
};

export default AgentUserProfileRightSide;
